/*
FUNCTIONS
*/

// Handles opening and closing of accordion tab
function onBundleAccordionClick() {
    var $this;

    if (!is_accordion) {
        is_accordion = true;

        if ($(this).parent().hasClass('bundle_product_opened')) {

            $(this).next('.accordion_contents').slideUp(200, bundleAccordionReset.bind(this, $.fn.removeClass));
        } else {

            $(this).next('.accordion_contents').slideDown(200, bundleAccordionReset.bind(this, $.fn.addClass));
        }
    }
}


// Handles opening and closing of accordion tab
function onAccordionClick() {

    var $this;

    if (!is_accordion) {

        is_accordion = true;

        // If accordion item opened, close it
        if ($(this).parent().hasClass('opened')) {

            $(this).next('.accordion_contents').slideUp(200, accordionReset.bind(this, $.fn.removeClass));
        } else {
            // Open the accordion item
            $(this).next('.accordion_contents').slideDown(200, accordionReset.bind(this, $.fn.addClass));
        }
    }
}

// Closes an accordion tab
function bundleAccordionReset(callback) {
    callback.call($(this).parent(), 'bundle_product_opened');
    is_accordion = false;
}

// Inject selected color in product form
function injectChosenColor() {
    if ($('.product_fields.color .chosen_color').length && $('#item_add_form .form_itm.color.checked').length) {
        $('.product_fields.color .chosen_color').html($('#item_add_form .form_itm.color.checked label').attr('title'));
    }
}

function promo_injectChosenColor() {
    if ($('.selection-offer-content .chosen_color').length) {
        $('.product_fields.color .chosen_color').html($('#item_add_form .form_itm.color.checked label').attr('title'));
    }
}


/*
    DOCUMENT READY & EVENT LISTENERS
*/

// Scripts executables once page has fully loaded
$(function() {
    if ($('.product_page').length) {
        // Floating add basket but with original element !== from
        $(window).on('load scroll', function () {
            if (!isInViewport($('.add_basket_float_container'))) {
                $('#add_basket_wrapper').addClass('floating');
                $('body').addClass('floating_add_basket');
                return;
            } else {
                $('#add_basket_wrapper.floating').removeClass('floating');
                $('body').removeClass('floating_add_basket');

            }
        })

    }
})

// Update cart
function onCartUpdate(response) {

    var cart_btn, modbox = 'item_error';
    var res = '';

    try {
        res = JSON.parse(response);
    } catch (e) {
        res = response;
    }

    if (!res.error) {
        response = response.trim().split("//");
        if (response[0] != false && response.length >= 0) {

            cart_btn = document.getElementById('cart_btn');
            if (/^\d/.test(this.data.name)) {
                if (response[0] == 1) {
                    response[0] += " lot de";
                } else {
                    response[0] += " lots de";
                }
            }
            response[0] += ' ' + this.data.name;

            if (this.data.size !== 'none') {
                response[0] += ' - Taille ' + (this.data.lbl_size || this.data.last_size);
            }

            if (this.data.colour !== 'none') {
                response[0] += ' - ' + (this.data.lbl_colour || this.data.last_colour);
            }
            if (response[1] == 0) {
                if (cart_btn) {
                    cart_btn.classList.add('none');
                    cart_btn.classList.remove('count');
                    cart_btn.removeAttr('data-cart');
                }
                // Toglle btn between cart and wishlist (Jonak)
                if ($('.toggleCartTypeBtn.basket').length) {

                    $('.toggleCartTypeBtn.basket .nb_products').html('0');
                }
            } else if (response[1] != false && response.length > 0) {
                if (cart_btn) {
                    cart_btn.classList.add('count');
                    cart_btn.classList.remove('none');

                    //Cas ou il n'y a pas encore de produit défini dans data-cart
                    if (!cart_btn.getAttribute('data-cart')) {
                        cart_btn.setAttribute('data-cart', parseInt(this.data.quantity))

                        // Toglle btn between cart and wishlist (Jonak)
                        if ($('.toggleCartTypeBtn.basket').length) {

                            $('.toggleCartTypeBtn.basket .nb_products').html(parseInt(this.data.quantity));
                        }
                    } else {
                        //On récupère le nombre de produits total dans le panier
                        var initialCount = cart_btn.getAttribute('data-cart');
                        //Et on ajoute à ce total la quantité choisie
                        var finalCount = parseInt(initialCount) + parseInt(this.data.quantity);
                        cart_btn.setAttribute('data-cart', finalCount);

                        // Toglle btn between cart and wishlist (Jonak)
                        if ($('.toggleCartTypeBtn.basket').length) {

                            $('.toggleCartTypeBtn.basket .nb_products').html(finalCount);
                        }
                    }
                }
            }
            if (this.data.configurator == 'true' && response[1] != false && response[4] == 0) {
                var data_conf = {};
                data_conf = this.data;
                data_conf.produit_id = this.data.idprod;
                data_conf.color_id = this.data.idcouleur;
                data_conf.size_id = this.data.idtaille;
                data_conf.conf_color_id = this.data.configurator_color;
                data_conf.conf_color_hex = this.data.configurator_color_hex;
                data_conf.conf_color_name = this.data.configurator_color_name;
                data_conf.conf_text = this.data.configurator_text;

                // Sauvegarde de la configuration du produit
                saveMobileConfigAddBasket(data_conf);
            }
            modbox = 'item_added';

            $(this).trigger('itmAddedToCart');

            // Réinitialisation composant achat express mobile v2
            if ($(this).length > 0 && $(this).find('.productSizeFieldset.actif').length > 0) {
                $(this).find('.productSizeFieldset.actif').removeClass('actif');
            }
        }
        // push data from response into DL
        if (response.length >= 0 && response[5] !== undefined) {
            var dl_objet = response[5];
            if (this.data.is_achat_express_input) {
                var new_dl_objet = JSON.parse(dl_objet)
                new_dl_objet.ecommerce.is_achat_express = true;
                dl_objet = JSON.stringify(new_dl_objet);
            }
            pushIntoDatalayer(dl_objet);
        }

        if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null) {
            if (response.length >= 0 && response[6] !== undefined) {
                var dlv3_objet = JSON.parse(response[6]);
                DataLayerTrigger.addToCart(dlv3_objet['items'], dlv3_objet['actionField'], this.data.is_achat_express_input);
            }
        }

        if (response[2]) {
            $('#total_basket').html(response[2]);
        }

    } else {

        $('#' + modbox).find('.modbox_title').html(res.error.msg);
    }

    window.setTimeout(function() {

        var loader = $('.loader');

        if (loader.hasClass('loading')) {

            loader.removeClass('loading');
        } else {

            loader.parent().removeClass('loading');
        }
    }, 400);

    // Basket page reload
    if ($('.toggleCartTypeBtn.basket').length) {

        let evt = new CustomEvent('tgToCartComplete');
        window.dispatchEvent(evt);
        location.reload();
    } else {

        modBoxOpen.call(document.getElementById(modbox));
    }
}