$( function () {
    setTimeout( () => {
        if($('#sliderBandoHeader .swiper-slide').length > 1) {
            var bandoHeaders = new Swiper('#sliderBandoHeader', {
                preloadImages: true,
                lazyLoading: true,
                loop: true,
                direction: 'horizontal',
                autoplay: 3000,
                speed: 1500,
                autoplayDisableOnInteraction: false,
                paginationClickable: true,
            });
        }
    }, 500 );
});

function sliderBandoHeaderMenuLeft() { 
    setTimeout( () => {
        if($('#sliderBandoHeaderMenuLeft .swiper-slide').length > 1) {
            var sliderBandoHeaderMenuLeft = new Swiper('#sliderBandoHeaderMenuLeft', {
                preloadImages: true,
                lazyLoading: true,
                loop: true,
                direction: 'horizontal',
                autoplay: 3000,
                speed: 1500,
                autoplayDisableOnInteraction: false,
                paginationClickable: true,
            });
        }
    }, 500);
}