function addToWishlistRay() {
    addToWishlist.call(this);
}

/**
 * Dans la fiche produit. Le bouton ajouter à la wishlist. (product_right.php)
 */
function addToWishlist(event) {
    var btn = $(this);
    var idElt = $(this).attr('data-idelt');
    var productId = $(this).attr('data-productid');
    var wishlistProductId = $(this).attr('data-wishlistproductid');
    var productRef = $(this).attr('data-product-ref');
    var wishlist = [];

    if (typeof(productId) == 'undefined') {
        var productId = $("#hdn_product_id").val();
    }

    var colorId = btn.attr('data-product-color-id');
    if (colorId == '' || colorId == 0 || typeof(colorId) == 'undefined') {
        colorId = $('#couleurProd_' + idElt).length == 1 ? $('#couleurProd_' + idElt).val() : $('#couleurProd').val();
    }

    var sizeId = btn.attr('data-product-size-id');
    if (sizeId == '' || sizeId == 0 || typeof(sizeId) == 'undefined') {
        sizeId = $('#tailleProd_' + idElt).length == 1 ? $('#tailleProd_' + idElt).val() : $('#tailleProd').val();
    }

    var datas = {
        product_id: productId,
        color_id: colorId,
        size_id: sizeId,
        need_color_id: 'false',
        need_size_id: 'false'
    };

    if (!btn.hasClass('existToWishlistButton')) {
        $.ajax({
            url: path_relative_root + create_link('ajax_add_to_wishlist'),
            type: 'post',
            data: datas
        })
        .done(function (resp) {
            var $alertboxLogin = $("#abox");
            $alertboxLogin.addClass('box_wishlist');

            if (resp.error) {
                if (resp.message == "login_required") {
                    var html =
                        '<div class="box_wishlist">' +
                        resp.result.error_message +
                        '<div class="btn_container">' +
                        '<button class="button dark" onclick="wishlistRedirectLogin();">' +
                        resp.result.btn_connexion +
                        "</button>" +
                        "</div>" +
                        "</div>";
                    $('#shad').addClass('actif');
                    if ($('#lightbox_achat_express').length > 0) {
                        $('#lightbox_achat_express').fadeOut("fast", function () {
                            $('#shad').css('display', 'none');
                        });
                    }
                    alert(html);
                    $("#abox .form_submit").css("display", "none");
                } else if (resp.result.error_message) {
                    alert(Translator.translate(resp.result.error_message));
                } else {
                    var message = resp.result.error_message ? resp.result.error_message : resp.message;
                    $(".disabledBtnTooltip").addClass('actif');
                    $(".wrap_btn_abox button span").html('ok');
                    if ($('.category').length > 0) {
                        alert(Translator.translate('error_wishlist_' + message.toLowerCase()));
                    }
                    return false;
                }
            } else {
                if (parseInt($('#in_basket').val()) === 1) {
                    var btn_wish = "#addToWishlistButton_" + product_id;
                    var span_wish = btn_wish + " span";
                    var del_product = "#del_product_" + product_id;

                    btn_wish = btn_wish.replace(',', '');
                    span_wish = span_wish.replace(',', '');
                    del_product = del_product.replace(',', '');

                    $(span_wish).html(resp.result.button_label);
                    $(btn_wish).attr("onclick", "").attr("href", path_relative_root + create_link('wishlist'));

                    addToWishlistUpdateHeader(resp.result.wishlist_size);
                    $(del_product)[0].click();
                } else {
                    $("#addToWishlistButton span").html(resp.result.button_label);
                    btn.attr("data-wishlistproductid", resp.result.wishlist_product_id);
                    btn.addClass("existToWishlistButton");
                    wishlist.push(resp.result.wishlist_row_array);
                    addToWishlistUpdateHeader(resp.result.wishlist_size);
                    changeBtnAddWishlist(true, productRef);
                }
            }
        });
    } else {
        $.ajax({
            url: path_relative_root + create_link('ajax_remove_from_wishlist'),
            type : 'post',
            data: { 
                    id: wishlistProductId,
                    product_id: productId,
                    color_id: colorId,
                    size_id: sizeId
                }
        })
        .done(function(resp) {
            if (!resp.error) {
                btn.removeClass("existToWishlistButton");
                btn.attr('data-wishlistproductid', '0');
            }
        });
    }
}

function getWishlistProducts() {
    let array_elements = document.querySelectorAll('a[data-productid]');
    let array_ids = [];

    // Collect all product IDs
    if (typeof array_elements !== 'undefined' && array_elements.length > 0) {
        for (let el of array_elements) {
            let id = el.getAttribute('data-productid');
            if (id && id !== 'undefined') {
                array_ids.push(id);
            }
        }

        // Prepare data to send as an array
        let datas = JSON.parse((JSON.stringify(Object.assign({}, array_ids))));

        // AJAX request to fetch wishlist data
        $.ajax({
            type: 'post',
            url: path_relative_root + create_link("get_user_all_wishlist"),
            data: datas,
            success: function (response) {
                // Check for the specific "Array" response
                if (response === 'Array') {
                    console.error('Server returned an invalid response: "Array"');
                    response = [];
                }

                // Parse the response if it's a string
                if (typeof response === 'string') {
                    try {
                        response = JSON.parse(response);
                    } catch (e) {
                        console.error('Error parsing JSON response:', e);
                        return;
                    }
                }

                // Ensure response is valid
                if (response && response.length > 0 && Array.isArray(response)) {

                    // Iterate through each product in the wishlist
                    for (let product_wishlist of response) {
                        let productId = product_wishlist.fk_produit_id;
                        let wishlistProductId = product_wishlist.wishlist_product_id;

                        // Apply the correct classes and attributes
                        $('a[data-productid="' + productId + '"]').addClass("in_wishlist existToWishlistButton");
                        $('a[data-productid="' + productId + '"]').attr('data-wishlistproductid', wishlistProductId);
                    }
                }
            },
            error: function (xhr, status, error) {
                console.error(`Error fetching wishlist: ${status} - ${error}`);
            }
        });
    }
}

$(function () {
    setTimeout(() => {
        getWishlistProducts();
    }, 200);
});