function changeLocale(locale, uri, site) {

    $.ajax({
        url: path_relative_root + create_link('ajax_get_uri_by_lang'),
        type: 'post',
        data: {
            lang: locale,
            url: uri,
            site:site
        },
        success: function (data) {
            if (data != "") {
                var force = "";
                if (uri == "/") {
                    force = "?force";
                }

                location.href = data + force;
            }
        }
    });

}

setTimeout(function () {

    if ($('.bandeauMenuAffix').length) {

        $('body').addClass('hasBandeau');
    }
}, 200);

function openMultiShad(id, fixed) {

    if (id == 'nrc_comment') {
        document.getElementById(id).style.zIndex = 1000;

        $('#shade').unbind('click').on('click', function () {
            closeMultiShad(id);
        }).addClass('visible');

        $("#" + id ).fadeIn(200);
    } else if ($('#' + id).hasClass('confirmDeleteProductBasket') || id == 'modboxpromo' || id == 'delivery_home_choice' || id == 'lightboxCustomerAddress' || id == 'relay_choice_12' || id == 'relay_choice_24' || id == 'selection-offer') {
        $('#shade').unbind('click').on('click', function () {
            closeMultiShad(id);
        }).css({
            opacity: 0,
            display: 'block',
        }).animate({
            opacity: 0.6
        }, 350);
        $('#' + id).addClass('actif');
    } else {
        var $lbId = $('#' + id);
        if ($lbId.length) {
            // for lightboxes that slide up from the bottom of the screen
            if (fixed == 'bottom' || id == 'popup_savoir_plus') {
                $lbId.addClass('bottom_lightbox');
                var height = $lbId.outerHeight();
                if ($lbId.children('.bottom_lightbox_close').length == 0) {
                    $lbId.append('<div class="bottom_lightbox_close" onclick="closeMultiShad(\'' + id + '\')"></div>');
                }
                $lbId.css({
                    opacity: 1,
                    display: 'block',
                    zIndex: 10000,
                    bottom: -height,
                    top: 'unset',
                    left: 0,
                    right: 0
                })
                .animate({
                    bottom: 0
                }, 300);

                $('#shade').unbind('click')
                .on('click', function () {
                    closeMultiShad(id);
                }).css({
                    opacity: 0,
                    display: 'block',
                    zIndex: '100'
                })
                .animate({
                    opacity: 0.6
                }, 300);
            } else {
                if ($('body').children('#' + id).length == 0)
                    $lbId.add($('#shade')).appendTo('body');
                $lbId.css({
                        // opacity: 0,
                        // display: 'block',
                        zIndex: 10000
                    })
                    // .animate({
                    //     opacity: 1
                    // }, 350, function () {
                    //     $lbId.css({
                    //         opacity: ''
                    //     });
                    // });
                if (fixed == 'fixed') {
                    var calculTop = $(window).scrollTop() + (($(window).height() / 2) - ($lbId.height() / 4));
                    $lbId.css({
                        position: 'absolute',
                        top: calculTop
                    });
                }


                $('#shade').unbind('click')
                    .on('click', function () {
                        closeMultiShad();
                    }).css({
                        opacity: 0,
                        display: 'block',
                        zIndex: '2'
                    })
                    .animate({
                        opacity: 0.6
                    }, 350);
            }
//
//             if ($('#lightbox_achat_express').length > 0 && id != 'lightbox_achat_express') {
//                 $('#lightbox_achat_express').fadeOut();
//                 $('#shade').data()['opened_lb'] -= 1;
//             }
//             if ($('#shade').data('opened_lb')) {
//                 $('#shade').data()['opened_lb'] += 1;
//             } else {
//                 $('#shade').data()['opened_lb'] = 1;
//             }
//             if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('7.') != -1) {
//                 $('#topMenuConnexionButtonInside').css({
//                     zIndex: 1
//                 });
//             }
        }
    }

    if (id == 'availabilityInStore') {
        $("#" + id).addClass('deployedlb');
    }
}

function closeMultiShad(id) {

    // Simpler multishad behaviour (optin via shad id)
    if (id == 'nrc_comment') {

        // shade
        $('#shade').unbind('click').removeClass('visible');
        $("#" + id).fadeOut(200, function () {
            $('#shade').unbind('click');
        });
    } else if ($('#' + id).hasClass('confirmDeleteProductBasket') || id == 'modboxpromo' || id == 'delivery_home_choice' || id == 'lightboxCustomerAddress' || id == 'relay_choice_12' || id == 'relay_choice_24' || id == 'selection-offer') {

        $("#" + id).removeClass('actif');
        $('#shade').fadeOut(200, function () {
            $('#shade').unbind('click');
        });
    } else {
        var array_id_popup = new Array('corona_notices_pop_up', 'corona_pop_up', 'mention_legales_pop_up', 'lightbox_achat_express', 'modbox', 'modbox_parrainage', 'modbox_error', 'sizeguidebox', 'sizeguideboxbague', 'alertbox', 'alertbox1', 'alertboxlogin', 'sendfriendbox', 'popup_inscription_home', 'errbox', 'popup_prix_reference', 'popup_savoir_plus', 'modboxpromo', 'gravurePopup', 'gravurePopupAssoc', 'selectionOffertePopup', 'sendwishlistbox', 'popup_garantie_img', 'lightbox_stock_alert', 'configurator_modal', 'contact_store_lightbox', 'ligthbox_send_store_information', 'geofiltre', 'item_added_with_infos', 'wishlist_share', 'availabilityInStore', 'alert_box'); // popups id list
        var cnt_id_popup = array_id_popup.length;

        if ($.inArray(id, array_id_popup) == -1 && id != '' && id != undefined) {
            var $lbId = $('#' + id);
            var startIdOpacity = Number($lbId.css('opacity'));
            // for lightboxes that slide down into the bottom of the screen
            if ($lbId.hasClass('bottom_lightbox')) {
                var height = $lbId.outerHeight();

                $lbId.animate({
                    bottom: -height
                }, 300);
            } else {
                if (id != "availabilityInStore") {
                    $lbId.css({
                        opacity: startIdOpacity
                    }).animate({
                        opacity: 0
                    }, 350, function () {
                        $lbId.css({
                            display: 'none',
                            opacity: ''
                        }).stop().clearQueue();
                    });
                }
            }

        } else {
            for (var i = 0; i < cnt_id_popup; i++) {
                if ($('#' + array_id_popup[i]).length && $('#' + array_id_popup[i]).is(':visible')) {
                    var $lbId = $('#' + array_id_popup[i]);
                    var startIdOpacity = Number($lbId.css('opacity'));
                    // for lightboxes that slide down into the bottom of the screen
                    if ($lbId.hasClass('bottom_lightbox')) {
                        var height = $lbId.outerHeight();
                        $lbId.animate({
                            bottom: -height
                        }, 300);
                    } else {
                        $lbId.css({
                            opacity: startIdOpacity
                        }).animate({
                            opacity: 0
                        }, 350, function () {
                            var lbdisplay;
                            if (id == 'availabilityInStore') {
                                lbdisplay = "block";
                            } else {
                                lbdisplay = "none";
                            }

                            $lbId.css({
                                display: lbdisplay,
                                opacity: ''
                            }).stop().clearQueue();
                        });
                    }
                }
            }

            var startShadOpacity = Number($('#shade').css('opacity'));
            $('#shade').css({
                opacity: startShadOpacity
            }).animate({
                opacity: 0
            }, 350, function () {
                $('#shade').css({
                    display: 'none',
                    opacity: ''
                });
                if ($('html').hasClass('ie7')) {
                    $('#shade')[0].style.removeAttribute('filter');
                    $('html, body').css({
                        overflow: ''
                    });
                }
            });
        }

        if (id == 'availabilityInStore') {
            $("#" + id).removeClass('deployedlb');
        }
    }
}

function onAttrSelect(idProduit) {
    var form = document.getElementById('item_add_form'),
        colors = form.itm_colour,
        sizes = form.itm_size,
        quantities = form.itm_quantity,
        color, size,
        produit_lot_nb = 0,
        dataParam = {};

    if (this.value) {
        this.classList.remove('error');
    }

    size = (sizes && sizes.selectedIndex !== -1 && sizes.value !== '0') ? sizes.value : 'none';

    if ($('#produit_lot_nb').length) {
        produit_lot_nb = $('#produit_lot_nb').val();
        if (size == 0) {
            size = 'none';
        }
    }

    var size_selected_product_id = 0
    if (size != 'none') {
        size_selected_product_id = $("#prod_taille option:selected").attr('data-produit_id');
    }

    color = (colors) ? colors.value : 'none';
    color_checked = (color != 'none') ? $('#color_' + color) : '';
    if (color_checked) {

        if (color_checked.attr('data-produit_id') != '') {
            if (size_selected_product_id == 0 || (size_selected_product_id > 0 && color_checked.attr('data-produit_id') == size_selected_product_id)) {
                idProduit = color_checked.attr('data-produit_id');
            }
        }
    }

    if (color !== 'none') {
        updateColor.call(color_checked);
        if (this !== window && this.classList.contains('color')) {
            setAvailableSizes.call(this);
        }
    }
    if (color == '') {
        if ($('input[name="firstColorStock"]') !== undefined) {
            var color = $('input[name="firstColorStock"]').val();
            if (color) {
                $('#prod_couleur input[value="' + color + '"]').prop('selected', true);
            }
        }
    }

    $('input[name="itm_id"]').val(idProduit);

    if (quantities) {
        if ($('#produit_lot_nb').length) {
            dataParam = {
                idprod: idProduit,
                idcouleur: color,
                idtaille: size,
                produit_lot_nb: produit_lot_nb
            };
        } else {
            dataParam = {
                idprod: idProduit,
                idcouleur: color,
                idtaille: size
            };
        }
        $.ajax({
            url: path_relative_root + create_link('ajax_liste_quantity'),
            type: 'get',
            data: dataParam,
            success: function (res) {
                quantities.parentElement.innerHTML = res.trim();
                checkStockCases();
                if ($("#date_du_ajax").val() == 0 && $("#date_au_ajax").val()) {
                } else {
                    $("#date_du").html($("#date_du_ajax").val());
                    $("#date_au").html($("#date_au_ajax").val());
                }
            }
        });
    }
}

/**
 * Updates the UI based on the stock case value retrieved from the element with ID 'case_ajax'.
 * 
 * The function handles three main cases:
 * - Case 5: Shows new product highlights, hides out-of-stock indicators, and updates various UI elements accordingly.
 * - Case 8: Shows out-of-stock indicators, hides new product highlights, and updates various UI elements accordingly.
 * - Other cases (1, 2, 3, 4): Shows product highlights, enables the add-to-basket wrapper, and updates various UI elements accordingly.
 * 
 * The function assumes that jQuery is available and that the elements being manipulated exist in the DOM.
 */
function checkStockCases() {
    if ($('#case_ajax') !== 'undefined') {
        let caseAjax = $('#case_ajax').val();
        if (caseAjax == '5') {
            // Case 5
            $(".eclat_nouveaute").removeClass('cache').show();
            $(".eclat_epuise").hide();
            $(".eclatProd").show().removeClass('cache');
            $("#bloc_add_alert").removeClass('cache').show();
            $("#add_basket_wrapper").hide();
            $(".stock_delivery").css('display', 'flex');
            $(".stock_info.yes").addClass('cache');
            $(".stock_info.no").removeClass('cache');
            $(".chez_vous_entre").addClass('cache');
        } else if (caseAjax == '8') {
            // Case 8
            $(".eclat_epuise").removeClass('cache').show();
            $(".eclat_nouveaute").hide();
            $(".eclatProd").hide();
            $("#add_basket_wrapper").hide();
            $("#add_basket_wrapper .loader_container #button_add").addClass('disabled');
            $("#button_add.disabled span").css("color", "#1d1d1d");
            $("#bloc_add_alert").hide();
            $(".stock_delivery").css('display', 'flex');
            $(".stock_info.yes").addClass('cache');
            $(".stock_info.no").removeClass('cache');
            $(".chez_vous_entre").addClass('cache');
        } else {
            // Case 1 / 2 / 3 / 4
            $(".eclatProd").show().removeClass('cache');
            $("#add_basket_wrapper").attr('disabled', false);
            $("#add_basket_wrapper").removeClass('cache').show();
            $("#bloc_add_alert").hide();
            $(".stock_delivery").css('display', 'flex');
            $(".stock_info.yes").removeClass('cache');
            $(".stock_info.no").addClass('cache');
            $(".chez_vous_entre").removeClass('cache').show();
        }
    }
}

function refreshProductPageElements(qty, form) {
    var parent_element = '';

    $(parent_element + ".eclatProd").removeClass('cache');
    value_stock = $(parent_element + '#qteProd option[value=1]').html();

    var res_qte_val = $(parent_element + "#qteProd").html();
    var regex = /choisir une couleur/;

    if ($("#date_du_ajax").val() == 0 && $("#date_au_ajax").val()) {
        $(parent_element + "#delivery_delay").hide();
    } else {
        $(parent_element + "#delivery_delay").show();
        $(parent_element + "#date_du").html($("#date_du_ajax").val());
        $(parent_element + "#date_au").html($("#date_au_ajax").val());
    }

    // Si stock dispo
    if (res_qte_val !== undefined) {
        //Si pas de stock DB + Stock physique + pas de date d'arrivage = on affiche le picto "bientot dispo"
        $(parent_element + ".eclat_nouveaute").removeClass('cache');
        $(parent_element + ".eclat_epuise").addClass('cache');
        $(parent_element + "#bloc_add_alert").show();
        $(parent_element + "#bloc_add_alert").removeClass('cache');
        $(parent_element + "#button_add").hide();
        $(parent_element + ".eclat_product_last_fiche_produit").addClass('cache');
        $(parent_element + ".eclatProd").addClass('cache');
    }
}

// popup PDV
function showSearchStore() {

    var shade, modbox;

    shade = document.getElementById('shade');
    modbox = document.getElementById('searchBox');

    // Make sure modbox will show on top of shade
    $(shade).after(modbox);

    // Show modbox and make sure closing routine won't fire more than once
    $(shade).off('click', closeSearchStore).fadeIn(200, function () {

        $(modbox).slideDown(600);
        $(shade).on('click', closeSearchStore);
    }).addClass('visible');
}

function closeSearchStore() {

    var shade = document.getElementById('shade');
    var modbox = document.getElementById('searchBox');

    // Hide modbox and shade and destroy shade's click event
    $(modbox).slideUp(600, function () {

        $(shade).off('click', closeSearchStore).stop(true, true).fadeOut(200);
    });
}

/********select_styled********/
$.fn.selectStyled = function () {
    var p = $(this);

    if (!$(".clone", p).length) {
        p.prepend("<div class='clone'></div>");
    }
    if ((p.hasClass('selectStyledProduct') && (!$(".arrow", p).length))) {
        p.prepend("<div class='arrow'></div>");
    }
    var c = $('.clone', p);
    var s = $('select', p);
    var ws = s.outerWidth();
    var hs = s.outerHeight();
    var ts = 2;
    var ls = 5;

    if (!p.is(':visible') && p.css('width') != undefined) {
        ws = Number(p.css('width').replace('px', ''));
    }

    s.ready(function () {
        s.css({
            "display": "block",
            "opacity": "0",
            "position": "absolute",
            "top": "0",
            "left": "0"
        });
        c.css({
            width: ws
        });
        c.text($('option:selected', s).text());
    });

    if ($(s).prop("value") == "" || $(s).prop("value") == undefined) {
        $(c).css({
            color: "#aaa",
            fontStyle: "italic"
        });
    }
    $(s).change(function () {
        if ($(this).prop("value") == "" || $(this).prop("value") == undefined || $(this).prop("value") == null) {
            $(c).css({
                color: "#aaa",
                fontStyle: "italic"
            });
        } else {
            $(c).css({
                color: "",
                fontStyle: ""
            });
        }
    });

    $(s).keypress(function () {
        $(this).blur();
        $(this).focus();
    });

    s.change(function () {
        c.text($('option:selected', s).text());
    });
};
//Affiche la liste des questions appropri�es au service demand�
function show_question(elementID, fieldID, idService, parentPath) {

    var list_question = document.getElementById(elementID),
        field = document.getElementById(fieldID);

    clearTimeout(time_out);

    if (list_question) {

        $.ajax({
            url: path_relative_root + create_link('ajax_list_question_contact'),
            type: 'post',
            data: {
                idService: idService
            },
            success: function (res) {

                list_question.innerHTML = res;
                field.innerHTML = '';

                $('.selectStyled', list_question).selectStyled();
            }
        });
    }
}

// Affiche les champs appropri�es au service et � la question demand�
function show_fields(elementID, idService, idQuestion, parentPath) {

    var list_field = document.getElementById(elementID);

    clearTimeout(time_out);

    if (list_field) {

        var response = ajax_file(parentPath + 'ajax_field_contact.php?idService=' + idService + '&idQuestion=' + idQuestion);

        $('.contact-beware a').attr('href', parentPath + create_link('connexion_login') + '?from=contact&id_service=' + idService + '&id_question=' + idQuestion);

        if (response) {

            list_field.innerHTML = response;

            $("#produit_ref_input").bind("keyup", function () {
                // Recuperation de tous les produit
                getAllProduct('produit_ref');
            });

            $("#produit_nom_input").bind("keyup", function () {
                // Recuperation de tous les produit
                getAllProduct('produit_nom');
            });

            $('.selectStyled', list_field).each(function () {

                $(this).selectStyled();
            });

            tabError = [];

            tabError[tabError.length] = ['alpha', 'contactNom', 'contact_field_nom'];
            tabError[tabError.length] = ['alpha', 'contactPrenom', 'contact_field_prenom'];
            tabError[tabError.length] = ['mail', 'contactMail', 'contact_field_mail'];

            if (idService == 7) {

                tabError[tabError.length] = ['alphanum', 'contactAdresse', 'contact_field_adresse'];
                tabError[tabError.length] = ['alphanum', 'contactCP', 'contact_field_cp'];
                tabError[tabError.length] = ['alphanum', 'contactVille', 'contact_field_ville'];
                tabError[tabError.length] = ['alphanum', 'contactPays', 'contact_field_pays'];

            } else {

                if (idService == 2 && idQuestion == 5) {

                    tabError[tabError.length] = ['alphanum', 'contactRefProduit', 'contact_field_ref_produit'];
                }

                if (idService == 14) {

                    if (idQuestion == 3) {

                        tabError[tabError.length] = ['alphanum', 'contactAdresse', 'contact_field_adresse'];
                        tabError[tabError.length] = ['phone', 'contactNumPortable', 'contact_field_num_portable'];
                        tabError[tabError.length] = ['alphanum', 'contactCP', 'contact_field_cp'];
                        tabError[tabError.length] = ['alphanum', 'contactVille', 'contact_field_ville'];
                        tabError[tabError.length] = ['select_date', 'garantieDateAchat', 'date_achat'];
                        tabError[tabError.length] = ['prix', 'contactPrix', 'prix'];
                        tabError[tabError.length] = ['alphanum', 'id_produit_ref', 'date_achat'];

                        $("#telephoneFake").intlTelInput({
                            utilsScript: parentPath + 'js/intlTelInputUtils.js'
                        });

                        // Date Achat validation
                        $('#btn_contact').click(function (e) {

                            e.preventDefault();

                            if ((document.getElementById('garantieDateAchatM').value === '') || (document.getElementById('garantieDateAchatA').value === '') || (document.getElementById('garantieDateAchatJ').value === '')) {
                                $('#garantieDateAchatLabel').addClass('labelErr');
                            } else {
                                $('#garantieDateAchatLabel').removeClass('labelErr');
                                $('.img_error').remove();
                            }

                            if ($('#id_produit_ref').val() == '') {
                                $('#produit_ref_input').attr('class', 'inputErr');
                                $('#produit_nom_input').attr('class', 'inputErr');
                                $('#id_produit_refLabel').attr('class', 'labelErr');
                                $('#produit_nom_inputLabel').attr('class', 'labelErr');

                                $('#id_produit_ref').after('<img style="margin: 2px 0 0 10px" src="/wshop-front/hesperide.com/htdocs/img/picto_panneau.png" alt="" class="img_error">');
                                $('#produit_nom_input').after('<img style="margin: 2px 0 0 10px" src="/wshop-front/hesperide.com/htdocs/img/picto_panneau.png" alt="" class="img_error">');

                            } else {
                                $('#produit_ref_input').attr('class', 'inputForm');
                                $('#produit_nom_input').attr('class', 'inputForm');
                                $('#id_produit_refLabel').attr('class', '');
                                $('#produit_nom_inputLabel').attr('class', '');
                            }

                            if ($('#contactMagasin').val() == '') {
                                $('#contactMagasin').after('<img style="margin: 2px 0 0 10px" src="/wshop-front/hesperide.com/htdocs/img/picto_panneau.png" alt="" class="img_error">');
                                $('#contactMagasinLabel').addClass('labelErr');
                            }
                        });
                    } else if (idQuestion == 2) {

                        var content = ajax_file('ajax_field_active_garantie.php');

                        if (content) {

                            $('#select_garantieNomProduit').append(content);
                            $('#field_garantieNomProduit').removeClass('cache');
                            $('#field_error_garantieProduit').addClass('cache');
                            $('#field_photo').addClass('cache');
                            $('#field_error_garantieProduit td').empty();
                        } else {

                            $('#field_garantieNomProduit').addClass('cache');
                        }
                    }
                }
            }

            if (!(idService == 3 && idQuestion == 2) && idService != 7) {

                tabError[tabError.length] = ['no_empty', 'contactMessage', 'contact_field_message'];
            }

            if ($('#g-recaptcha').length > 0) {
                grecaptcha.render('g-recaptcha');
            }
        } else {

            list_field.innerHTML = '';
        }
    }
}

function getAllProduct(type) {

    var value = $('#' + type + '_input').val();
    var min_length = $('#' + type + '_input').data('minlength');

    if (min_length == '')
        min_length = 0;

    // Construction des parametre à passer au script
    var values = {
        search: value,
        all_product: true
    };

    // On recherche à partir du nombre de caractere definie sur le champs
    if (value.length >= min_length) {

        $.ajax({
            'type': 'POST',
            'data': values,
            'url': path_relative_root + 'ajax_get_' + type + '.php',
            'async': false,
            'success': function (response) {
                autoCompleteAll(response, type, value);
            }
        });
    }
}

function remove_shad() {
    $('#shade').fadeOut(200, function () {});
}

$(function () {
    /**
    * Swiper mobile "Mon compte" menu
    */
    if (($('body').hasClass('customer') || $('body').hasClass('newsletter') || $('body').hasClass('order') || $('body').hasClass('wishlist')) && $('#menu_account_slider_mob').length > 0) {
        var menuInitialSlide = 0;

        $('.menu_account_slider_mob').show();
        $('.menu_account_slider_mob .swiper-slide').each(function (i, obj) {
            if ($(obj).hasClass('activeMenu')) {
                menuInitialSlide = i;
            }
        });

        var menuAccSwiperMob = new Swiper('#menu_account_slider_mob', {
            slidesPerView: 1,
            initialSlide: menuInitialSlide,
            spaceBetween: 0,
            width: 110,
            slidesOffsetBefore: 20,
            slidesOffsetAfter: 190,
            freeMode: true,
            roundLengths: true,
            onInit: function () {
                $('.menu_account_slider_mob').fadeTo("slow", 1);
            },
        });
    }

    //INIT SWIPER HOME MODULE THREE IMAGES
    if ($('.three-images .insideImg .home-column').length > 0) {
        window.setTimeout(function () {
            var swiperThreeImages = new Swiper('.three-images', {
                slidesPerView: 1,
                centeredSlides: true,
                paginationClickable: true,
                loop: true,
                speed: 2000,
                autoplay: 2000,
                nextButton: '.swiper-button-next',
                prevButton: '.swiper-button-prev'
            });
        }, 250);
    }

    if ($('#company_profils .swiper-slide').length > 1) {
        var swiperGondolBoutique = new Swiper('#company_profils', {
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            parallax: true,
            speed: 1500,
            loop: true,
            autoplay: 1500,
        });
    }

     // Page store locator
    if ($('body').hasClass('store_locator') || $('body').hasClass('satellite_store_details')) {

        if ($('#sliderBando').length) {
            $('body').addClass('calibrate_body');
        }

        if ($('.img_store .swiper-slide').length > 1) {
            var img_store = new Swiper('.img_store ', {
                slidesPerView: 1,
                pagination: '.store_img .swiper-pagination',
                paginationClickable: true
            });
        }
    }

    if ($('.products_list_text .swiper-container').length > 0) {
        const products_list_text = new Swiper('.products_list_text .swiper-container', {
            slidesPerView: 1,
            pagination: '.products_list_text .swiper-pagination'
        });
    }

    if ($('.products_list:not(.products_list_2) .swiper-container').length > 0) {
        const products_list_text = new Swiper('.products_list .swiper-container', {
            slidesPerView: 1,
            pagination: '.products_list .swiper-pagination'
        });
    }

    // swiper module image + text + list
    if ($('.products_list_2 .swiper-container').length > 0) {
        const swiperImageTextList = new Swiper('.products_list_2 .swiper-container', {
            slidesPerView: 1,
            spaceBetween: 10,
            loop: true,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            pagination: '.products_list_2 .swiper-pagination',
            paginationType: 'progress',
        });
        // When clicking on next/prev buttons, scroll to top of module
        $('#swiperImageTextList .swiper-button').on('click', function () {
            $('html, body').animate({
                scrollTop: $('#swiperImageTextList').offset().top - $('.banner_nav').height()
            }, 500);
        });
    }

    if ($('.old-post .swiper-container').length > 0) {
        const oldPost = new Swiper('.old-post .swiper-container', {
            slidesPerView: 1,
            pagination: '.old-post .swiper-pagination'
        });
    }

    // CHECK INITIALISATION CODE
    if ( $( '#menu_cms_slider .swiper-slide' ).length > 1 ) {
        var newSwiper = new Swiper('#menu_cms_slider', {
            slidesPerView: 3,
            spaceBetween: 10,
            navigation: {
                nextEl: '#cms_subcategory_menu .swiper-button-next',
                prevEl: '#cms_subcategory_menu .swiper-button-prev',
            },
        });

        if($('#menu_cms_slider .swiper-slide').length <= 3) {
            $('#cms_subcategory_menu .swiper-button-prev').hide();
            $('#cms_subcategory_menu .swiper-button-next').hide();
            $('#menu_cms_slider .swiper-wrapper').css('justify-content', 'center');
        }

        if ($('#cms_subcategory_menu .item').hasClass('selected')) {
            $('#menu_cms_slider .swiper-slide').each(function (index) {
                if ($(this).find('[id^="csc_menu_"]').hasClass('selected')) {
                    $('#cms_subcategory_menu .see_all').removeClass('selected');
                    newSwiper.slideTo(index);
                }
            });
        } else {
            $('#cms_subcategory_menu .see_all').addClass('selected');
        }
    }

    // page CMS
    $('.cms-page-module.socialMedia').insertBefore('.cms-page-module.old-post');

    $('#paiement_sofort').click(function () {
        $('#paiement_sofort .bank_subtitle').addClass('loader_sofort');
    });

    $('.giropay .bank_subtitle').click(function () {
        $('#hipay_issuer_bank_id, #btn_cmd_valid').addClass('displayInputGiro');
    });

    $('.giropay #btn_cmd_valid').click(function () {
        $('.giropay #btn_cmd_valid').addClass('loader_giropay');
    });

    if ($('.page_satellite').length) {
        var pageSatellite = new Swiper('.page_satellite', {
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            centeredSlides: true,
            slidesPerView: 'auto',
            spaceBetween: 20,
        });

        var path = window.location.pathname;
        if ( path.length > 1 ) {
            $('.brandMenuMobile a[href*="' + path + '"]').addClass('select_active');
            $('.brandMenuMobile a[href*="' + path + '"]').parent().addClass('select_active_swiper');
        }
        var index = $('.page_satellite .swiper-wrapper').children();
        for(var j=0; j<index.length;j++){
            var elm = index[j];
            if ($(elm).hasClass('select_active_swiper')) {
                break;
            }
        }
        pageSatellite.slideTo(j);
    }

    if ($('.product_page').length) {
        if ($('.form-store-availability').length) {
            $('.title-location').on('click tap touch', function () {
                openMultiShad('geofiltre', 'bottom');
            });
        }
    }

    if ($('.home-module.images_signup_module').length) {
        $('.home_menu').hide();
        $('.footer_links').hide();
    }

    if ($('.home-module.images_signup_module').length) {
        $('.home_menu').hide();
        $('.footer_links').hide();
    }
    // hide popin alert geoloc
    if ($(".store_locator").length > 0) {
        $('#alert_box').css('opacity', 0);
        $('.modal_shade').css('opacity', 0);
    }

    // Hide Pagination on main slider HP - If we got only one swiper-slide
    if ($('#main_slider .swiper-slide').length < 2) {
        $('#main_slider .swiper-button-next, #main_slider .swiper-button-prev').hide();
    }

    // Hide Pagination on main slider HP - If we got only one swiper-slide
    if ($('#main_cover_slider .swiper-slide').length < 2) {
        $('#main_cover_slider .swiper-button-next, #main_slider .swiper-button-prev').hide();
    }

    // add swiper autoplay attribute to handle main slider autoplay delay
    if ($('#main_cover_slider .swiper-slide').length > 1) {

		var mainCoverSlider = new Swiper('#main_cover_slider', {
			nextButton: '.swiper-button-next',
			prevButton: '.swiper-button-prev',
			parallax: true,
			speed: 1500,
			loop: true,
			autoplay: 8000,
			pagination: '.swiper-pagination',
			onInit: function (swiper) {

				if (('#main_cover_slider .swiper-pagination').length > 1) {

					$('.swiper-pagination').show();
				}
			}
		});
    }

    /**
     * MODULE INSTAGRAM SLIDER
     */
    if ($('.social-module .swiper-slide').length > 1) {
        setTimeout(function(){
            var insta_slider = new Swiper(".social-module .swiper-container", {
                slidesPerView: 2,
                spaceBetween: 10,
            });
        }, 800);
    }

    /**
     * GONDOLE HOME SLIDER
     */
    if ($('.swiper-slide', '.tg_home_slider').length > 1) {

        setTimeout(function(){

            var tg_home_slider = new Swiper(".tg_home_slider", {
                slidesPerView: 2,
                spaceBetween: 10,
            });
        }, 800);
    }

    /**
     * HOME SLIDER 2
     */
    if ($('#home_slider2 .swiper-slide').length > 1) {
        var homeSliderNew = new Swiper('#home_slider2', {

            slidesPerView: 1,
            loop: true,
            speed: 1500,
            autoplay: 1500,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',

        });
    }
    // Hide Pagination on home slider 2 HP - If we got only one swiper-slide
    if ($('#home_slider2 .swiper-slide').length < 2) {
        $('#home_slider2 .swiper-button-next, #home_slider2 .swiper-button-prev').hide();
    }

    /**
     * HOME SLIDER 3
     */

    var homeSliderElement = $('.module-slider_image .swiper-container');
    if (homeSliderElement.length) {
        homeSliderElement.each(function () {
            if ($(this).find('.swiper-slide').length > 1) {
                new Swiper("#" + $(this).attr('id'), {
                    slidesPerView: 1,
                    loop: true,
                    speed: 1500,
                    autoplay: 8500,
                    pagination: '.swiper-pagination_module_home',
                });
            }
        });
    }

    /*** GONDOLE SEARCH NO RESULT ***/
    if ($('#wrapper_products_home .swiper-wrapper .swiper-slide').length > 1) {
        setTimeout(function(){
            var tg_search_slider = new Swiper("#wrapper_products_home.swiper-container", {
                slidesPerView: 2,
            });
        }, 300);
    }

    customDropdown('picto_faq');
    customDropdown('picto_matiere');
    customDropdn('contenu');
    customDropdn('subcontenu');

    if ($('.sliderCover').length) {
        $('.sliderCover').each(function () {
            if ($(this).find('.swiper-slide').length > 1) {
                var home_slider = new Swiper($(this), {
                    slidesPerView: 1,
                    loopedSlides: 0,
                    loop: true,
                    autoplay: 12000,
                    nextButton: $(this).children('.swiper-button-next'),
                    prevButton: $(this).children('.swiper-button-prev')
                });
            }
        });
    }

    // Add Read More if text seo > 150 char
    if ($(".seo_div").length || $(".wrapper_bandeau.bandoBottom .txt_content_container").length) {
        var pSeo = document.querySelector(".seo_txt");
        var pSeoBrut = document.querySelector(".seo_txt_brut");
        var aReadmore = document.querySelector("a.readmore");
        var textSeoBrut = pSeoBrut.innerHTML;
        var miniTextSeo = "";
        var maxLenghtText = 150;

        if (maxLenghtText > textSeoBrut.length) {
            aReadmore.style.display = "none";
            pSeo.style.display = "none";
            pSeoBrut.style.display = "block";
        } else {
            pSeoBrut.style.display = "none";

            for (let i = 0; i <= maxLenghtText; i++) {
                miniTextSeo += textSeoBrut[i];
            }

            miniTextSeo = miniTextSeo.trim();
            miniTextSeo += "...";
            pSeo.innerHTML = miniTextSeo;
        }
    }

    $('.btn_close').on('click', function() {
        setTimeout(function () {
            $('#wrapper_tri_filtre legend').removeClass('open');
            $('#wrapper_tri_filtre .filter_options').css('display','none');
        }, 500);

    });

    // focus label input text #pdvStoreBox
    if ($('.w-contact-form').length) {
        form_animatePlaceholder('.w-contact-form');
    }

    if ($('#wishlist_share').length) {
        form_animatePlaceholder('#wishlist_share');
    }

    if($('#descrip_store').length && $('#descrip_store').text().length > 500){
        var description = $('#descrip_store');
        var trigger = $('.content_descrip .trigger');
        var originH = description.height();

        description.css('height', '136px');
        trigger.show();

        trigger.on('click touch', function(){
            if($(this).hasClass('open')){
                description.css('height', '136px');
                trigger.removeClass('open');
                trigger.text(Translator.translate('read_more'));
            } else {
                description.css('height', originH + 'px');
                trigger.addClass('open');
                trigger.text(Translator.translate('read_less'));
            }
        });
    }

    if ($('#showSortingFilter').length) {
        $('#showSortingFilter').on('click', function () {
            toggleMenu('filters', 'right');
            window.setTimeout(function () {
                $('#wrapper_tri_filtre legend').addClass('open');
                $('#wrapper_tri_filtre .filter_options').slideDown(400);
            }, 600);
        });
    }

    if ($('#showSortingFilterAlgo').length) {
        $('#showSortingFilterAlgo').on('click', function () {
            toggleAlgoFilters()
            window.setTimeout(function () {
                $('#filter_trier_holder').parents('.filters_dropdown_bloc').addClass('body_visible');
                $('#filter_trier_holder').parents('.toggleFilter').next().css('display','block');
            }, 600);
        });
    }

    $("#item_added .form_submit .button.valid").click(function () {
        $(this).addClass('loading');
    });

    if ($('.product_page #zoom_container').length && $('.product_page #product_pictures_slider').length) {
        var productSwiperFp = new Swiper('#zoom_container .swiper-container', {
            slidesPerView: 1,
            mode: 'horizontal',
            centeredSlides: true,
            pagination: '.swiper-pagination',
            paginationClickable: true,
            preloadImages: true,
            lazyLoading: true,
            loop: true,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev'
        });
    }

    $("body").on('DOMSubtreeModified', "#flowbox-container", function() {
        var bannerFlowboxTitle = document.getElementById("banner_flowbox_title");
        if (bannerFlowboxTitle == null ) {
            var createDivFlowboxTitle = '<div id="banner_flowbox_title"><p class="banner_h1">' + Translator.translate('banner_flowbox_title') + '</p><p class="banner_h2">' + Translator.translate('banner_flowbox_subtitle') + '</p></div>';
            $(createDivFlowboxTitle).insertBefore("#flowbox-container");
        }
    });

    // FP lookbook swiper init
    if ($('.lookbook #zoom_container').length && $('.product_page #product_pictures_slider').length) {
        var productSwiperFp = new Swiper('#zoom_container .swiper-container', {
            slidesPerView: 1,
            mode: 'horizontal',
            centeredSlides: true,
            pagination: '.swiper-pagination',
            paginationClickable: true,
            preloadImages: true,
            lazyLoading: true,
            loop: true,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev'
        });
    }

    if ($('body.category').length > 0) {
        new Swiper('.wrapper_seo_liens', {
            slidesPerView: 'auto',
            spaceBetween: 10,
            // Additional configuration options can be added here
        });
    }

    // SLIDE SEO CATEGORY MODULE
    if ($('body .lienSeo').length > 0) {
        $('.lienSeo .textSeo_bloc_liens').addClass('swiper-wrapper');
        $('.lienSeo .textSeo_bloc_list_items').addClass('swiper-slide');
        // Initialiser Swiper
        new Swiper('.lienSeo', {
            slidesPerView: 'auto',
            spaceBetween: 13
        });
    }

    // Init all sliders on product_apge
    if ($('.product_page').length) {
        initFpSliders();
    }

    setTimeout(() => {
        // Remove name from menu left Mon compte
        $("#left_menu .menu_item a.user.connected span").text(Translator.translate("my_account"));

        $('#m_animator .pushCateg').each(function () {
            if ($(this).find('.swiper-slide').length > 1) {
                var pushCateg = new Swiper($(this), {
                    scrollbar: $(this).find('.swiper-scrollbar'),
                });
            }
        });
    }, 500);
    retourToSscat();

    /* Close search bar after pressing close after searching */
    $(".algoliaSearchBar #autocomplete .aa-ClearButton").click(function () {
        setTimeout(() => {
            searchBtn();
        }, 200);
    });

    /* Move 3rd menu buton see_all_btn to top */
    setTimeout(() => {
        $("#main_menu ul.list_menu + ul.list_menu + ul.list_menu li.see_all_btn").each(function () {
            $(this).prependTo($(this).parent());
        });
    }, 200);

    if ($('.bandeauMenuAffix').length > 0) {
        var isTop = true;

        $(document).scroll(function () {
            if ($(window).scrollTop() === 0 && !isTop) {
                isTop = true;
                $(".bandeauMenuAffix").fadeTo("fast", 1).css('pointer-events', 'auto');
            } else if ($(window).scrollTop() > 1 && isTop) {
                isTop = false;
                $(".bandeauMenuAffix").fadeTo("fast", 0).css('pointer-events', 'none');
            }
        });
    }

    setTimeout(() => {
        if ($("#left_menu #sliderBandoHeader").length > 0) {
            $("#left_menu #sliderBandoHeader").attr("id", "sliderBandoHeaderMenuLeft");

            setTimeout(() => {
                sliderBandoHeaderMenuLeft();
            }, 200);
        }
    }, 500);

    setTimeout(() => {
        $(".algoliaSearchBar .aa-InputWrapperSuffix .aa-ClearButton").on("click", function () {
            $(".algoliaSearchBar").removeClass("changeBgColor");
        });

        $('.algoliaSearchBar .aa-InputWrapper .aa-Input').on('input focus', function () {
            if ($(this).val() === '') {
                $('.algoliaSearchBar').removeClass('changeBgColor');
            }
        });
    }, 150);

    var coverSliderElement = $('.cms-page-module.tg-module .swiper-container');
    if (coverSliderElement.length) {
        coverSliderElement.each(function () {
            if ($(this).find('.swiper-slide').length > 1) {
                new Swiper("#" + $(this).attr('id'), {
                    preloadImages: false,
                    slidesPerView: 2,
                    spaceBetween: 10,
                    lazy: true,
                    loop: false,
                    // grab the sibling of the swiper-container to find the navigation buttons
                    // coverSlider_[X] + .wrap_navigation contains the prev and next buttons and the scrollbar
                    // yes, the selectors are reversed for some reason...
                    nextButton: '#' + $(this).attr('id') + ' + .wrap_navigation .swiper-module-prev',
                    prevButton: '#' + $(this).attr('id') + ' + .wrap_navigation .swiper-module-next',
                    scrollbar: '#' + $(this).attr('id') + ' + .wrap_navigation .swiper-scrollbar',
                    scrollbarDraggable: true,
                    scrollbarHide: false,
                });
            }
        });
    }

    // footer swiper
    new Swiper('.footer_products_sections .swiper-container', {
        roundLengths: true,
        setWrapperSize: true,
        slidesPerView: 2,
        nextButton: '.footer_products_sections_next',
        prevButton: '.footer_products_sections_prev',
        pagination: '.footer_products_sections_progress',
        paginationType: 'progress'
    });
    // click on the next arrow then the previous arrow to properly display the progress bar
    $('.footer_products_sections_next').click();
    $('.footer_products_sections_prev').click();
    // grab the highest height of the swiper slides' headers and apply it to all
    let highestHeight = 0;
    $('.footer_products_sections .swiper-slide').each(function () {
        let slideHeight = $(this).find('.product_section_header').height();
        if (slideHeight > highestHeight) {
            highestHeight = slideHeight;
        }
    });
    $('.footer_products_sections .swiper-slide .product_section_header').height(highestHeight);

    // footer collapse
    $('.about_section .title').on('click', function (e) {
        e.preventDefault();
        // get the target
        var $target = $(this).closest('.about_section');

        if ($target.hasClass('open')) {
            $target.removeClass('open');
            $target.find('.satellites').slideUp();
        } else {
            // close all other open elements
            $('.about_section.open').not($target).removeClass('open');
            $target.addClass('open');

            // make all other .about_section .satellites slide up
            $('.about_section .satellites').not($target.find('.satellites')).slideUp();
            $target.find('.satellites').slideDown();
        }
    });

    // Added item lightbox
    $("#item_added_with_infos .form_submit a").on('click', function() {
        $(this).parent().find('.loader').addClass('loading');
    })

    $(window).on('sendFormUpdateCart_complete', function(){
        openMultiShad('item_added_with_infos', 'bottom');
        $('#shade').removeClass('visible').off('touchend', modBoxClose);
    });

    if ($('#wishlist_share').length) {
        checkWishlistForm();
    }

    if ($('.tunnel_track_pusher').length) {

        setTimeout(function () {

            $('.tunnel_track_pusher').addClass('displayed');
        }, 500);
    }

    if ($('#main_cart_wrapper').length) {

        setTimeout(function () {

            $('#main_cart_wrapper').addClass('displayed');
        }, 500);
    }

    if ($('.tunnel.body_login').length) {

        setTimeout(function () {

            $('.newlogin_page').addClass('displayed');
        }, 500);
    }

    if ($('.pushBasketWrapper').length || $('.pushBasketWrapperText').length) {
        $('.pushBasketWrapper').insertBefore('.cart_main_title.basket');
        $('.pushBasketWrapperText').insertBefore('.cart_main_title.basket');
    }

    if ($('body.cart.step_1').length) {
        updateCartElements();
    }

    if ($('.toggleWrapper').length) {

        $(document).on("click", ".toggleWrapper", function () {

            if ($(this).hasClass('active')) {

                $(this).children('.toggleContent').slideUp();
                $(this).removeClass('active');
            } else {

                $('.toggleContent').slideUp();
                $('.toggleWrapper').removeClass('active');
                $(this).children('.toggleContent').slideDown();
                $(this).addClass('active');
            }
        });
    }

    /**
     * Init function if '.formPanier_container' available on the current page
     */
    if ($(".formPanier_container").length && !$(".checkout").length) {
        setTimeout(function () {
            formPanierClass();
        }, 500);
    }

    if ($('.thanksWrapper').length && $('#deny-optin').length) {

        $('#deny-optin').insertAfter('.bankThanksBtn');
    }

    moveProductOutOfStock();


    // Store the current URL
    let currentUrl = window.location.href;
    
    // Set an interval to check for URL changes every 500ms
    setInterval(() => {
      // If the URL has changed, update the current URL and call updateEnableErase
      if (window.location.href !== currentUrl) {
        currentUrl = window.location.href;
        updateEnableErase();
      }
    }, 500);
    
    updateEnableErase();

    initFormItmSelection();
});

/**
 * Move out of stock products to the top of the cart
 */
function moveProductOutOfStock() {

    if ($('.cart_product_line.out_of_stock').length) {
        $('.cart_product_line.out_of_stock').each(function () {
            $(this).slideUp(300, function () {
                $(this).insertBefore('.toggleCartType');
                $(this).slideDown();
            });
        });
    }
}

/**
 * Switch cart btn class depending on its viewport position
 */
function formPanierClass() {
    let formPanier_container = $(".formPanier_container");
    let cart_total = $("#cart_total");

    let elementBottom = 0;

    if (formPanier_container.length) {
        elementBottom = formPanier_container.offset().top + formPanier_container.innerHeight();
    }

    if ($(document).scrollTop() + $(window).height() <= elementBottom) {
        formPanier_container.addClass("fixed");
        cart_total.addClass("hasFixedValidation");
    } else {
        formPanier_container.removeClass("fixed");
        cart_total.removeClass("hasFixedValidation");
    }

    $(window).on("scroll", function () {

        if ($(document).scrollTop() + $(window).height() <= elementBottom) {
            formPanier_container.addClass("fixed");
            cart_total.addClass("hasFixedValidation");
        } else {
            formPanier_container.removeClass("fixed");
            cart_total.removeClass("hasFixedValidation");
        }
    });
}

/**
 * Toggle product details in cart right column
 */
$(document).on("click", ".total_produit", function () {

    $(this).toggleClass("open");
    $(".total_produit_dropdown").slideToggle();
    $(".total_produit_dropdown").overlayScrollbars({});
});

/*
 * Display relay opening hours
 */
$(document).on('click', '.btn_toggle_hours', function (e) {
    $(this).toggleClass('open');
    $(this).next('.openingHours').toggleClass('open');
});

function initSwiperTunnel() {
    if ($('.cart_main_table.wishlist .swiper-slide').length) {
        var wishlistCart = new Swiper('.cart_main_table.wishlist', {
            slidesPerView: 2,
            paginationClickable: true,
            spaceBetween: 12,
            preloadImages: true,
            lazyLoading: true,
            loop: false,
            nextButton: '.cartWishNext',
            prevButton: '.cartWishPrev',
            scrollbar: '.cartWishScroll',
            scrollbarDraggable: true,
            scrollbarHide: false,
        });
    }

    if ($('#buyTogetherSlider .swiper-slide').length) {
        var buyTogetherSlider = new Swiper('#buyTogetherSlider', {
            slidesPerView: 2,
            paginationClickable: true,
            spaceBetween: 12,
            preloadImages: true,
            lazyLoading: true,
            loop: false,
            nextButton: '.buyTogetherNext',
            prevButton: '.buyTogetherPrev',
            scrollbar: '.buyTogetherScroll',
            scrollbarDraggable: true,
            scrollbarHide: false,
        });
    }
}

function updateCartElements() {

    if ($('.toggleCartTypeBtn.wishlist').length) {
        $('.toggleCartTypeBtn.wishlist').insertAfter($('#tunnel_right_col'));
    }

    if ($('.cart_main_table.wishlist').length) {
        $('.cart_main_table.wishlist').insertAfter($('.toggleCartTypeBtn.wishlist'));
    }

    if ($('.buyTogetherWrapper').length) {
        $('.buyTogetherWrapper').insertAfter($('.cart_main_table.wishlist'));
    }

    if ($('.share_cart').length && !$('.toggleCartType .share_cart').length) {
        $('.share_cart').insertAfter($('.toggleCartTypeBtn.basket'));
    }

    setTimeout(function () {
        initSwiperTunnel();
    }, 500);
}

$(window).on("wishToBasket_complete", function () {

    initSwiperTunnel();

    // get the first appearance of .product_line_basket
    var first_product = $('.product_line_basket').first();

    // scroll to first_product position, or to the top if none
    if (first_product.length) {
        $('html, body').animate({
            scrollTop: first_product.offset().top - 160
        }, 500);
    } else {
        $('html, body').animate({
            scrollTop: 0
        }, 500);
    }

    formPanierClass();

    if (wShop.$refs.wCouponForm) {
        wShop.$refs.wCouponForm.reloadBloc += 1;
    }
});

$(window).on("basketToWish_complete", function () {

    setTimeout(function () {
        initSwiperTunnel();
    }, 500);

    if (wShop.$refs.wCouponForm) {
        wShop.$refs.wCouponForm.reloadBloc += 1;
    }
});

$(window).on("tgToCartComplete", function () {

    // scroll to .cart_product_line last child position
    var lastChild = $('.cart_product_line:last-child');
    var lastChildPosition = lastChild.offset().top - 100;
    $('html, body').animate({
        scrollTop: lastChildPosition
    }, 500);
});

$(window).on("removeFromWish_success", function () {

    setTimeout(function () {
        initSwiperTunnel();
    }, 500);

    if (wShop.$refs.wCouponForm) {
        wShop.$refs.wCouponForm.reloadBloc += 1;
    }
});

$(window).on("addressFormDisplayed", function () {
    setTimeout(function () {
        formPanierClass();
    }, 250);
});

/**
 * Pour vérifier si le champs du stoleLocator est bien renseigné
 * @param form
 * @returns {boolean}
 */
function checkHomeStoreLocator(form) {
    var autocomplete = $('.autocomplete_addr', $(form));
    if (autocomplete.val() === '') {
        autocomplete.addClass('inputErr');
        return false;
    } else {
        autocomplete.removeClass('inputErr');
        return true;
    }
}

function show_lightbox_3xcb() {
    $('#shade').fadeTo("slow", 0.8).queue(function () {
        $(this).css('display', 'block');
        $('#popup_3xcb').slideDown("slow");
        $(this).bind('click', close_lightbox_3xcb);
        $(this).dequeue();
    });
}

/* Slider pages nos creations*/
if ($('.swiper-container.nos_creations .swiper-slide').length > 1) {
    var swiper = new Swiper('.swiper-container.nos_creations', {
        speed: 600,
        parallax: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            renderBullet: function (index, className) {
                return '<span class="' + className + '">' + (index + 1) + '</span>';
            },
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
} else {
    $('.swiper-container.nos_creations .paginations').css('display', 'none');
}

function customDropdown(stringClassName) {
    var acc = document.getElementsByClassName(stringClassName);
    var i;

    for (i = 0; i < acc.length; i++) {
        acc[i].onclick = function () {
            var removeElemName = this.nextElementSibling.className;
            $('.' + removeElemName).removeClass('show');
            $('.' + stringClassName).removeClass('active');
            this.classList.toggle("active");
            this.nextElementSibling.classList.toggle("show");
            if ($(this).hasClass('active') && this.nextElementSibling.className.indexOf("show") == -1) {
                $(this).removeClass('active');
            }
        }
    }
}

function customDropdn(stringClassName) {
    var acc = document.getElementsByClassName(stringClassName);
    var i;

    for (i = 0; i < acc.length; i++) {
        acc[i].onclick = function () {
            var element_class = $(this).attr("class");
            var theclass = element_class.split(' ')[0];
            if ($(this).hasClass('open')) {
                $(this).removeClass('open');
                $('.faq_content.show li .' + theclass + '.elements .subcontenu').removeClass('open');
                $('.faq_content.show li.open .' + theclass + '.elements').removeClass('open');
                $(this).parent('li').removeClass('open');
            } else {
                $('.' + stringClassName).removeClass('open');
                $('.' + stringClassName).parent('li').children('.' + theclass + '.elements').removeClass('open');
                $('.' + stringClassName).parent('li').children('.' + theclass + '.elements .subcontenu').removeClass('open');
                $('.' + stringClassName).parent('li').removeClass('open');

                $(this).parent('li').addClass('open');
                this.classList.toggle("open");
                $('.faq_content.show li.open .' + theclass + '.elements').addClass('open');
            }
        }
    }
}

// Sends message
function sendMessage(id, message_send, message_fail) {

    var errbox = document.getElementById('item_error');
    var form = $('#form_reply')[0];
    var fd = new FormData(form);
    var objElement = document.getElementById("loader_1");
    var item_error = document.getElementById('item_error');
    if ($('#msg_txt_' + id).val() == '') {

        $('.modbox_title', errbox).html("Veuillez saisir votre réponse");
        objElement.style.display = "none";
        item_error.style.display = "block";
        openModalBox.call(errbox);
        return false;
    }
    if ($('#msg_txt_' + id).val() != '') {

        $.ajax({
            type: 'post',
            url: path_relative_root + create_link('message_detail'),
            data: fd,
            processData: false, // dire à jQuery de ne pas traiter les données
            contentType: false, // dire à jQuery de ne pas définir le contentType
            cache: false,
            success: function (response) {

                if (response == '1') {
                    item_error.style.display = "block";
                    $('.modbox_title', errbox).html(message_send);
                    window.setTimeout(function () {
                        window.location.reload();
                    }, 3000);

                } else {
                    $('.modbox_title', errbox).html(message_fail);
                }
            }
        });
    } else {
        $('.modbox_title', errbox).html(message_fail);
    }

    openModalBox.call(errbox);
}

function uploadfile() { //fonction pour valider le fichier en pj dans le formulaire message

    var error = false;
    var image = $('#imagepj1')[0].files[0];
    var fsize = typeof image != "undefined" ? image.size : 0;; //get file size
    var ftype = typeof image != "undefined" ? image.type : ''; // get file type
    var reset_image = document.getElementById('reset_image');
    var allowedType = new Array('image/jpeg', 'image/png'); // Type de fichiers autoris�s
    if (fsize > 4000000) {
        alert('Poids Max : 4Mo');
        error = true;
    } else if (jQuery.inArray(ftype, allowedType) == -1) {
        alert('Format autorisé : jpg, png');
        error = true;
    }
    if (error) {
        $('#imagepj1').val('');
    } else {
        $('.msgBox .image_msg p').text($('#imagepj1')[0].files[0].name);
        reset_image.style.display = "block";
    }
}

function addFileInput2(id_input, string) {

    var field = (typeof (string) != 'undefined') ? string : 'contactProduitPhoto';
    var field_str = field;
    id = parseInt(id_input);
    var next_file = id + 1;
    var file = $('#' + field + id_input)[0].files[0];

    var error = false;
    var input = field == 'contactProduitPhoto' ? $('#photoProduit_display') : $('#photoProduitEndommage_display');

    var nb_input = field == 'contactProduitPhoto' ? $(input).val() : $(input).val();
    var reset_file = document.getElementById('reset_file');
    nb_input = parseInt(nb_input);
    var size = typeof file != "undefined" ? file.size : 0;
    var type = typeof file != "undefined" ? file.type : '';
    var allowedType = new Array('image/jpeg', 'image/png', 'application/pdf'); // Type de fichiers autoris�s
    if (size > 4000000) {
        alert('Poids Max : 4Mo');
        error = true;
    } else if (jQuery.inArray(type, allowedType) == -1) {
        alert('Format autorisé: jpg, png, pdf');
        error = true;
    }
    if (error) {
        if (id_input == 1) {
            $('#field_send').addClass('cache');
            $('#' + field + next_file).remove();
        }
        $('#' + field + id_input).val('');
    } else {
        if (id_input == 1) {
            $('#field_send').removeClass('cache');
            $('.msgBox .file_msg p').text($('#filepj1')[0].files[0].name);
            reset_file.style.display = "block";
        }
        if (field != 'filepj') {
            var params = (typeof (string) != 'undefined') ? ',"' + string + '"' : '';
            var style = (typeof (string) != 'undefined' && string != 'contactProduitPhotoEndommage') ? 'style="float: left; padding-left: 32px;padding-top:-2px;"' : '';
            $('#' + field + id_input).after("<input " + style + " class='product_file' id='" + field + next_file + "' value='' type='file' name='" + field + next_file + "' onchange='addFileInput2(" + next_file + params + ")' /> ");
            $(input).attr('value', next_file);
        }
    }
}

function addImageInput(id_input, string) {

    var field = (typeof (string) != 'undefined') ? string : 'contactProduitImageEndommage';
    id = parseInt(id_input);
    var next_file = id + 1;
    var file = $('#' + field + id_input)[0].files[0];
    var error = false;
    var input = '';
    var field_str = '';
    switch (field) {
        case "contactProduitPhoto":
            field = input = $('#photoProduit_display');
            field_str = "contactProduitPhoto";
            break;
        case "contactProduitImage":
            field = input = $('#imageProduit_display');
            field_str = "contactProduitImage";
            break;
        case "contactProduitImageEndommage":
            field = input = $('#imageProduitEndommage_display');
            field_str = "contactProduitImageEndommage";
            break;
    }

    var nb_input = field == 'contactProduitPhoto' ? $(input).val() : $(input).val();
    nb_input = parseInt(nb_input);
    var reset_image = document.getElementById('reset_image');
    var size = typeof file != "undefined" ? file.size : 0;
    var type = typeof file != "undefined" ? file.type : '';
    var allowedType = new Array('image/jpeg', 'image/png'); // Type de fichiers autoris�s
    if (size > 5000000) {
        alert('Poids Max : 4Mo');
        error = true;
    } else if (jQuery.inArray(type, allowedType) == -1) {
        alert('Format autorisé : jpg, png');
        error = true;
    }
    if (error) {
        if (id_input == 1) {
            $('#field_send').addClass('cache');
        }
        $('#' + field_str + id_input).val('');
    } else {

        if (id_input == 1) {
            $('#field_send').removeClass('cache');

            if (field_str == 'contactProduitImageEndommage') {

                $('#notif_image2').text($('#contactProduitImageEndommage1')[0].files[0].name);
                reset_image.style.display = "block";
            }

            if (field_str == 'contactProduitImage') {
                $('#notif_image').text($('#contactProduitImage1')[0].files[0].name);
                reset_image.style.display = "block";
            }
        }
        if (field != "filepj") {
            $(input).attr("value", next_file);
        }

    }
}

function resetFile(id_input) {

    if (id_input == "filepj1") {
        var file = document.getElementById('filepj1');
        var reset_file = document.getElementById('reset_file');
        reset_file.style.display = "none";
        $('.msgBox .file_msg p').text('');
        file.value = '';
    }
    if (id_input == "imagepj1") {
        var image = document.getElementById('imagepj1');
        var reset_image = document.getElementById('reset_image');
        reset_image.style.display = "none";
        $('.msgBox .image_msg p').text('');
        image.value = '';
    }

    if (id_input == 'contactProduitPhotoEndommage') {
        var file2 = document.getElementById('contactProduitPhotoEndommage1');
        var reset_file2 = document.getElementById('reset_file');
        reset_file2.style.display = "none";
        $('.msgBox .file_msg p').text('');
        file2.value = '';
    }

    if (id_input == 'contactProduitImageEndommage') {
        var image2 = document.getElementById('contactProduitImageEndommage1');
        var reset_image2 = document.getElementById('reset_image');
        reset_image2.style.display = "none";
        $('.msgBox .image_msg p').text('');
        file2.value = '';
    }

}

$(document).scroll(function () {
    if ($(window).scrollTop() === 0 ) {
        $('body').removeClass('search_hidden searchonscrolled');
    } else if ($(window).scrollTop() > 1) {
        $('body').addClass('search_hidden');
    }
});

if ($("#prod_couleur input").length < 2) {
    window.setTimeout(function () {
        $('#prod_couleur').css({"background-image": "inherit"});
    }, 50);
}

// FP

window.setTimeout(function () {
    initProductVisited();
}, 100);

function goBack(toUrl) {
    if (toUrl) {
        window.location.href = path_relative_root;
    } else {
        var url = window.location.href;
        if (url.indexOf("#search") > -1) {
            var new_url = url.substring(0, url.indexOf("#search"));
            window.location.href = new_url;
        } else if (url.indexOf("conseil") > -1) {
            if ($(".conseil_home").hasClass('actif')) {
                window.history.back();
            } else {
                $(".item_conseil").removeClass("actif");
                $(".conseil_home").addClass("actif");
            }
        } else {
            window.history.back();
        }
    }

}

$('#contents_wrapper .store_horaire .alert').appendTo('#contents_wrapper .store_horaire');

$( "#zoom_container .prod_wishlist_btn, #zoom_container .prod_wishlist_btn #addToWishlistButtonTxt" ).remove();

// init oomprtement popin alert geoloc
window.onload = function(){
    if ($(".store_locator").length > 0) {
        setTimeout(function(){
            $('#alert_box').removeClass('open');
            $('.modal_shade').removeClass('visible');
            $('#alert_box').css('opacity', 1);
            $('.modal_shade').css('opacity', 1);
        }, 500);
    }
}

/* CDN */

var carouselException = 'associations';

var newlogin_title_1 = Translator.translate('newlogin_title_1');
var account_quick_access = Translator.translate('account_quick_access');
var popup_savoir_plus = Translator.translate('subscribe_preview');
var mon_compte_title = Translator.translate('my_account');

setTimeout(function(){
    $(".newlogin_page .login_bloc .login_form_wrapper .title h3").replaceWith(('<h1 class="sub_2">' + newlogin_title_1 + '</h1>'));
    $("body.body_login .newlogin_page .login_bloc .title h4").replaceWith(('<h2 class="quick_rs">' + account_quick_access + '</h2>'));
    $("body.body_login .newlogin_page .login_bloc .title h4").replaceWith(('<h2 class="quick_rs">' + account_quick_access + '</h2>'));
    $(".super_boutik .home_menu .super_categ_menu a.actif span").wrapInner("<h1></h1>");
    $("#popup_savoir_plus .modal_title_box h2.modal_title").replaceWith(('<p class="modal_title">' + popup_savoir_plus + '</p>'));
    $(".wrapper_mon_compte .newlogin_page .login_bloc .title.my_account").replaceWith(('<p class="mon_compte_title">' + mon_compte_title + '</p>'));

    $('.store_locator #wrapper_search_shop .wrapper_cadre .formStore h3').each(function() {
        var formStoreh3 = $(this);
        $(this).replaceWith('<div class="h3Title form_title">' + formStoreh3.text() + '</div>');
    });

}, 1500);

setTimeout(function(){
    $(".super_boutik .home_menu .menu_home_categ.actif .categ_menu .btn.vector_sprite span").wrapInner("<h2></h2>");

    $('.customer.body_login .site_bottom .rassurance .rassurance_item .rassurance_desc').each(function() {
        var formStoreh3 = $(this);
        $(this).replaceWith('<h3 class="rassurance_desc">' + formStoreh3.text() + '</h3>');
    });

    $('#cookiesLightbox section h4').each(function() {
            var cookiesh4 = $(this);
            $(this).replaceWith('<p class="h4style">' + cookiesh4.text() + '</p>');
    });

}, 2000);

/* FIN CDN */

/**
 * Checks if a product is in the user's wishlist.
 *
 * @returns {boolean} Returns true if the product is in the wishlist, false otherwise.
 */
function checkProductInWishlist() {
    let prodId = $("#produit_id").val();

    let couleurProd = $("#prod_couleur");
    if (couleurProd.length) {
        couleurProd = $("#prod_couleur .color[selected]").val();
    } else {
        couleurProd = 0;
    }

    let tailleProd = $("#prod_taille");
    if (tailleProd.length) {
        tailleProd = $("#prod_taille [checked]").val();
        if (tailleProd == '' && $("body.product_page").length && $("#tailleProd").length) {
            tailleProd = $("#tailleProd").val();
        }
    } else {
        tailleProd = 0;
    }

    if (typeof prodId != "undefined" && typeof couleurProd != "undefined" && typeof tailleProd != "undefined") {
        let datas = {
            product_id: prodId,
            size_id: tailleProd,
            color_id: couleurProd
        };

        $.ajax({
            type: 'get',
            url: path_relative_root + create_link('get_user_wishlist'),
            data : datas,
            success: function (response) {
                let userWishlist = JSON.parse(response);
                let result = (userWishlist.length) ? true : false;
                changeBtnAddWishlist(result);
                return result;
            }
        });
    } else {
        changeBtnAddWishlist(false);
        return false;
    }
}

/**
 * Retrieves wishlist products and updates the DOM accordingly.
 * @function getWishlistProductsRay
 * @returns {void}
 */
function getWishlistProductsRay() {
    let array_elements = document.querySelectorAll('a[data-productid]');
    let array_ids = [];
    if (typeof array_elements != 'undefined' && array_elements.length > 0) {
        for (el of array_elements) {
            var id = el.getAttribute('data-productid');
            if (id != '' && id != 'undefined') {
                array_ids.push(id);
            }
        }
        let data = JSON.parse((JSON.stringify(Object.assign({}, array_ids))));

        $.ajax({
            type: 'get',
            url: path_relative_root + create_link("get_user_all_wishlist"),
            data: data,
            success: function (response) {
                // if (typeof response != 'undefined' && response.length > 0 && (typeof response == 'object')) {
                if (typeof response != 'undefined') {
                    let array_product_id;

                    try {
                        array_product_id = JSON.parse(response);
                    } catch (e) {
                        return;
                    }

                    for (product_wishlist in array_product_id) {
                        $('a[data-productid = "' + array_product_id[product_wishlist].fk_produit_id + '"]').addClass("in_wishlist"); // in_wishlist utilisé sur hesperide et potentiellement d'autres sites
                        $('a[data-productid = "' + array_product_id[product_wishlist].fk_produit_id + '"]').addClass("existToWishlistButton");
                        $('a[data-productid = "' + array_product_id[product_wishlist].fk_produit_id + '"]').attr('data-wishlistproductid', array_product_id[product_wishlist].wishlist_product_id);
                    }
                } else {
                    return;
                }
            }
        });
    }
}

function initItemSliderRayon(isToggled) {
    if ($('.prodItemSlider, .searchItemSlider').length) {
        var swiperLazy = is_lazy_load ? false : true; // is_lazy_load defined in _app -> views_mobile_new -> main.php
        if (isToggled == true) { // Toggle view is triggerred
            setTimeout(function () {
                $('.prodItemSlider, .searchItemSlider').each(function () {
                    if ($(this).hasClass('initialized')) {
                        this.swiper.update();
                        this.swiper.slideTo(1);
                    }
                });
            }, 200); // 200 equals the css transition timing defined on .item_container
        } else { // From page load and scroll
            $('.prodItemSlider').each(function () {
                if (isInViewport($(this)) && !$(this).hasClass('initialized')) { // Prevent initialized sliders to go back to the first frame during scroll
                    new Swiper($(this), {
                        roundLengths: true,
                        slidesPerView: 1,
                        loop: true,
                        preloadImages: false,
                        lazyLoading: swiperLazy,
                        observer: true,
                        observeParents: true,
                        pagination: '.itemSliderPagination',
                    });
                    $(this).addClass('initialized');
                }
            });

            $(".searchItemSlider").each(function () {
                if (isInViewport($(this)) && !$(this).hasClass('initialized')) {
                    new Swiper($(this), {
                        roundLengths: true,
                        slidesPerView: 1,
                        loop: true,
                        preloadImages: false,
                        lazyLoading: swiperLazy,
                        observer: true,
                        observeParents: true,
                        pagination: '.itemSliderPagination',
                    });
                    $(this).addClass('initialized');
                }
            });
        }
    }
}
/**
 * Load global .item_container slider initialization on window scroll
 */
setTimeout(function () {
    initItemSliderRayon(false);
},100);

$(window).on("scroll", function () {
    initItemSliderRayon(false);
});

/*
 * Permet de changer l'action et le visuel du bouton d'ajout à la wishlist
 */
function changeBtnAddWishlist(in_wishlist) {
    var btn = $('.vector_sprite.wish')
    if (typeof(button_add_to_wishlist_initial_click_event) == "undefined") {
        button_add_to_wishlist_initial_click_event = btn.attr("onclick");
    }
    if (in_wishlist) {
        btn.removeClass("addToWishlistButton").addClass("existToWishlistButton");
    } else {
        btn.attr("onclick", button_add_to_wishlist_initial_click_event);
        btn.removeClass("existToWishlistButton").addClass("addToWishlistButton");
    }
}

/**
 * Loads product details and updates the UI accordingly.
 *
 * This function handles product selection, updates the product details, and refreshes
 * the UI elements such as images, stock information, and sliders. It supports both
 * regular and express purchase flows.
 *
 * @param {string} type_filter - The type of filter applied to the product (e.g., color, size).
 * @param {string} element - The element that triggered the product load.
 *
 * @returns {void}
 */
function loadProduct(type_filter, element) {
    var is_wishlist = false;
    var is_achat_express_v2 = (this.form !== undefined && this.form.elements['achatExpressV2'] !== undefined && !is_wishlist);
    var isProdKit = (typeof(produit_kit_id) !== 'undefined');
    var taille_id = $('.size input[name=itm_size]:checked').val();

    if (isProdKit) {
        id_prod = $(this).attr('data-idprod-img');
    } else {
        id_prod = "";
    } 

    type_page = 'product';

    var $form = '';
    var data = '';

    if (!is_achat_express_v2) {
        $form = $('#item_add_form');

        data += $form.serialize();

        var couleur_id = 0;
        if (isProdKit) {
            couleur_id = $(this).attr('value');
            $produit_id_new = $(this).attr('data-idprod-img');
        } else {
            couleur_id = $("input[name=itm_colour]:checked").val();
            $produit_id_new = $(element).attr('data-produit_id');
        }

        data += '&couleurProd=' + couleur_id;
        data += '&tailleProd=' + taille_id;
        data += '&type=' + type_filter;

        data += '&couleurProd=' + $("input[name=itm_colour]:checked").val();
        data += '&tailleProd=' + $('input[name=itm_size]:checked').val();
        data += '&produit_principal=' + $('#produit_principal' + id_prod).val();
        var produit_principal = $('#produit_principal' + id_prod).val();

        data += '&produit_id_new=' + $produit_id_new;

        var div_produit_achat_express_v2 = $(this).parents('.swiper-slide');

    } else {
        var div_produit_achat_express_v2 = $(this).parents('.item') ? $(this).parents('.item') : $(this).parents('.swiper-slide');
        var swiper_achat_express_v2 = $(this).parents('.swiper-container');

        $form = $(this.form);
        var id_prod = "";
        $form = this.form;

        var old_id = this.form.elements['produit_id'].value;
        var isCategSuper = this.form.elements['isCategSuper'].value;
        var new_id = undefined;
        var cpt_prod = '';
        var couleur_id = 0;
        if (isProdKit) {
            couleur_id = $(this).attr('value');
            new_id = $(this).attr('data-idprod-img');
        } else {
            couleur_id = $(this).val();
        }

        data += 'produit_id=' + old_id;
        data += '&produit_principal=' + old_id;
        data += '&couleurProd=' + couleur_id;
        data += '&tailleProd=';
        data += '&qteProd=1';
        data += '&hdn_product_id=' + old_id;
        data += '&produit_id_new=' + new_id;
        data += '&type=' + type_filter;
        data += '&is_achat_express=0';
        data += '&is_achat_express_v2=1';
        data += '&cpt_prod=' + (cpt_prod - 1);
        data += '&isCategSuper=' + isCategSuper;
    }

    $.ajax({
        type: 'post',
        url: path_relative_root + create_link('ajax_get_product_regroup_ref'),
        data: data,
        beforeSend: function () {
            $('#bloc_btn_loader').show();
            $('#btn_error').fadeOut(300);
        },
        success: function (response) {
            try {
                response = $.parseJSON(response);
            }
            catch (e) {
                console.error('parseJSON');
                return;
            }

            if (response.success == 'ok') {

                if (window.dataLayer !== undefined) {
                    window.dataLayer.push({
                        'event': 'view_item',
                    });
                }

                var datas = response.datas;
                if (type_page == 'product') {

                    var $html_main = $(datas.html_main);
                    if (isProdKit) {
                        //set slider product
                        $("#zoom_container").replaceWith($("#zoom_container", datas.html_main));

                        if ($("#zoom_container .swiper-slide").length > 1 ) {
                            var slidesPerView = $('#zoom_container').data('slidesperview');
                            if (slidesPerView == undefined) {
                                slidesPerView = 1;
                            }
                            var spaceBetween = $('#zoom_container').data('spacebetween');
                            if (spaceBetween == undefined) {
                                spaceBetween = 0;
                            }
                            var prodSwiperSlider = new Swiper('#zoom_container .swiper-container', {
                                slidesPerView: slidesPerView,
                                mode: 'horizontal',
                                centeredSlides: true,
                                pagination: '.swiper-pagination',
                                paginationClickable: true,
                                preloadImages: true,
                                lazyLoading: true,
                                spaceBetween: spaceBetween,
                                loop: true,
                                nextButton: '.swiper-button-next',
                                prevButton: '.swiper-button-prev'
                            });
                        }
                    } else if (is_achat_express_v2) {
                        // Photo et information
                        div_produit_achat_express_v2.replaceWith($html_main);
                        if ($('#oneProduct') != undefined && $('#oneProduct').hasClass('actif')) {
                            $('.itm_achat_express_' + new_id).addClass('full');
                        }

                        if (swiper_achat_express_v2) {
                            if (typeof (four_images) != 'undefined') {
                                enableSwiperFourImage();
                            } else {
                                var id_swiper = swiper_achat_express_v2.attr('id');
                                refreshSliderAchatExpress(id_swiper);
                            }
                        }
                        setTimeout(function () {
                            initItemSliderRayon(false);
                        }, 200);
                    } else {
                        var product_refext = $('#produit_refext', $html_main).val();
                        var avisVerifiesReviews = $('.nrc_customizable_wrapper', $html_main).parent();

                        var $fiche = $('#contents_wrapper');
                        // /!\ You WILL NOT be able to target direct children of $fiche, you will need to nest them
                        var replace_main = new Array(
                            '#wrapper_product_name',
                            '#zoom_container',
                            '.prod_page_bot',
                            '.compo',
                            '.associations_section',
                            '.accordion_container',
                            '.product_infos .price_container',
                            '.cms-page-module',
                            'w-netreviews-stars',
                            '.description_courte',
                            '.caracNav',
                            '.resume',
                            '.netreviews_reviews',
                            '#product_gammes_container',
                        );
                        for (var i = 0; i < replace_main.length; i++) {
                            var id = replace_main[i];

                            if (id == '.accordion_container') {

                                // Recover each accordion_item id so we can replace each one individually
                                $('.accordion_item', $fiche).each(function () {
                                    var acc_item_id = $(this).attr('id');

                                    if ($("#" + acc_item_id, $html_main).length) {
                                        // Is item was previously hidden, remove cache class
                                        if ($(this).hasClass('cache')) {
                                            $(this).removeClass('cache')
                                        }

                                        // Update item content in fiche with ajax response content
                                        $(this).html($("#" + acc_item_id, $html_main).html());
                                    } else {
                                        // If fiche accordion item isn't present in ajax response, hide it by adding cache class
                                        $(this).addClass('cache');
                                    }
                                });

                            } else if (id == '.description_courte') {
                                // Bloc pas accessible directement sur html_main (méthodo juste au-dessus), obligé de contourner avec cette méthode - EX : Messegue
                                $html_main.each(function () {
                                    if ($(this).hasClass('description_courte')) {
                                        $('.description_courte', $fiche).html($(this).html());
                                    }
                                });
                            } else if (id == '.caracNav') {
                                // Example : Sabon FP Description --> Item description isn't build as an accordion like most wshops
                                // Description is working as tabs

                                // Titles
                                $('.caracLabel', $html_main).each(function () {
                                    var item_id = $(this).attr('id');
                                    $("#" + item_id, $fiche).html($(this).html());
                                });

                                // Contents matching titles
                                $('.caracWrapper', $html_main).each(function () {
                                    var item_id = $(this).attr('id');
                                    $("#" + item_id, $fiche).html($(this).html());
                                });

                            } else {
                                // TODO: fetch elements in a NATIVE way to make it more performant and more reliable (no more forced nesting)

                                if ($(id, $fiche).length && $(id, $html_main).length) {
                                    if(id == "#zoom_container"){
                                        $(id, $fiche).html("");
                                        $(id, $fiche).html($(id, $html_main).html());
                                    } else {
                                        $(id, $fiche).html($(id, $html_main).html());
                                    }
                                } else {
                                    if ($(id, $fiche).length && !$(id, $html_main).length) {
                                        $(id, $fiche).replaceWith('');
                                    }
                                }

                                if (id == "#zoom_container") {
                                    if ($('.product_page #zoom_container').length && $('.product_page #product_pictures_slider').length) {
                                        new Swiper('#zoom_container .swiper-container', {
                                            slidesPerView: 1,
                                            mode: 'horizontal',
                                            centeredSlides: true,
                                            pagination: '.swiper-pagination',
                                            paginationClickable: true,
                                            preloadImages: true,
                                            lazyLoading: true,
                                            loop: true,
                                            nextButton: '.swiper-button-next',
                                            prevButton: '.swiper-button-prev'
                                        });
                                    }
                                }

                                if (id == '#gamme_product_carousel') {
                                    var swiper = new Swiper('.ranges_carousel_wrapper.ranges .swiper-container.gamme_product_carousel', {
                                        slidesPerView: 2,
                                        paginationClickable: true,
                                        spaceBetween: 12,
                                        preloadImages: true,
                                        lazyLoading: true,
                                        loop: false,
                                        nextButton: '.ranges_carousel_wrapper.ranges .swiper-button-next',
                                        prevButton: '.ranges_carousel_wrapper.ranges .swiper-button-prev',
                                        scrollbar: '.ranges_carousel_wrapper.ranges .swiper-scrollbar',
                                        scrollbarDraggable: true,
                                        scrollbarHide: false,
                                    });
                                }
                            }
                        }

                        // Update "avis verifiés" reviews
                        updateReviews(avisVerifiesReviews, product_refext);

                        if ($('#publication_page', $html_main).length > 0) {
                            $('#publication_page').html($('#publication_page', $html_main).html());
                        } else {
                            $('#publication_page').html('');
                        }

                        var string_params = '';
                        if ($("#produit_lot_nb").val() != undefined) {
                            string_params += '&produit_lot_nb=' + $("#produit_lot_nb").val();
                        }

                        if ($('input[name=itm_size]:checked').val() > 0) {
                            // RECHARGEMENT QTE
                            var qte = ajax_file(path_relative_root + create_link('ajax_liste_quantity') + '?idprod=' + datas.product.id + '&idcouleur=' + datas.product.color.id + '&idtaille=' + $('input[name=itm_size]:checked').val() + string_params);
                            $('#liste_qte').html(qte);
                        }
                        onAttrSelect(parseFloat(datas.product.id));

                        var contentMovable = $('#content_movable');
                        $('.jqzoom').on('doubletap', function () {
                            zoomImg.call(this);
                            $('#zoomBox').addClass('active');

                            contentMovable.addClass('visible');
                            var zoomboxWidth = $('body').width(),
                                scrollTo = zoomboxWidth / 2;
                            setTimeout(function () {
                                $('#zoomBox').scrollLeft(scrollTo);
                            }, 'slow');
                        });

                        contentMovable.on('doubletap', function () {
                            $(this).removeClass('visible');
                            $('#zoomBox .btn_close').trigger('click');
                        });

                        //check stock product
                        var hasStock = false;
                        var stocks = response.stock;
                        $.each(stocks, function(index, stock){
                            if (Array.isArray(stock)) {
                                $.each(stock, function (key, val) {
                                    if (val === true) {
                                        hasStock = true;
                                    }
                                });
                            }
                        });

                        //si on a du stock, on affiche les éléments correspondant
                        if (hasStock) {
                            if ($("#bloc_add_basket") !== undefined) {
                                $("#bloc_add_basket").show();
                            }
                            if ($("#link_book_in_store") !== undefined) {
                                $("#link_book_in_store").show();
                            }
                            if ($("#bloc_add_alert") !== undefined) {
                                $("#bloc_add_alert").hide();
                            }
                            //sinon, on les cache
                        } else {
                            if ($("#bloc_add_basket") !== undefined) {
                                $("#bloc_add_basket").hide();
                            }
                            if ($("#link_book_in_store") !== undefined) {
                                $("#link_book_in_store").hide();
                            }
                            if ($("#bloc_add_alert") !== undefined) {
                                $("#bloc_add_alert").show();
                            }
                        }
                    }

                    if ($('.product_page').length) {
                        if ($('.form-store-availability').length) {
                            $('.title-location').on('click tap touch', function () {
                                openMultiShad('geofiltre', 'bottom');
                            });
                        }
                    }

                }

                // RECHARGEMENT STYLE INPUT
                $('.selectStyled').each(function () {
                    $(this).selectStyled();
                });

                if (document.getElementById("alma-widget") !== null) {
                    initAlmaWidget();
                }

                if (typeof nrSearchForStars !== "undefined" && $('.NETREVIEWS_PRODUCT_STARS').length) {
                    nrSearchForStars();
                }

                initHighlightButtons();

            } else {
                $('#btn_error').fadeIn();
            }
        },
        complete: function () {
            $('#bloc_btn_loader').fadeOut(300);

            if (type_page == 'product') {
                // Function used to put the color name next to the selection label in product form
                injectChosenColor();

                // Init all sliders on product_apge
                initFpSliders();
            }

            // Update breadcrumb product name
            if ($('#ajax_product_name').text().length > 0) {
                $('#ariane_home > span').text($('#ajax_product_name').text());
            }

            if (window.lazyload) {
                lazyload.init();
            }
            var form = document.getElementById('item_add_form');
            if (!isProdKit) {
                if (is_achat_express_v2) {
                    form = $form;
                } else {
                    colors = form.itm_colour;
                    color = (colors) ? colors.value : 'none';
                    color_checked = (color != 'none') ? $('#color_' + color) : '';
                    updateColor.call(color_checked);
                    checkProductInWishlist();
                }
            }
            setTimeout(function () {
                $(".zoom_indicator").fadeOut(800)
            }, 2500);

            // Recheck products in Wishlist
            setTimeout(function () {
                getWishlistProducts();
            }, 250);

            // After swiper load, reload dailymotions videos
            try {
                initDailymotionVideos();
            } catch (e) {
                console.error(e);
            }
        }

    });
}

function close_lightbox_3xcb() {
    $('#popup_3xcb').hide();
    $('#shad').hide();
    closeMultiShad();
    $(this).unbind('click', close_lightbox_3xcb);
}

function xMasDilveryTimestamp() {
    var now = Math.floor( Date.now() / 1000 );

    if ( $( '#timestamp_check' ).length > 0 ) {
        now = $( '#timestamp_check' ).attr('data-date');
    }

    if ( now >= 1667430000 && now < 1671231600 ) {
        $('.tunnel_step_title').after('<p class="xmas_delivery">' + Translator.translate( 'livraison_garantie' ) + '</p>');

    //  17/12 à 20/12
    } else if ( now >= 1671231600 && now < 1671577200 ) {
        if ( $( "body" ).hasClass( "fr" ) ) {
            $( '.tunnel_step_title' ).after( '<p class="xmas_delivery">' + Translator.translate( 'xmas_delivery' ) + '</p>' );
        }
    }
}

if ($('body').hasClass('step_2') && $('.tunnel_step_title').length) {
    xMasDilveryTimestamp();
}

/* FIN CDN */

function createDatalayer(prod_id, url) {
    if (typeof productlist_gondole != 'undefined'){
        for ( var i = 0 ; i < productlist_gondole.length; i++) {
            prod = productlist_gondole[i];
            if (prod.id == prod_id){
                window.list = prod['list'];
                delete prod['list'];
                dataLayer.push({
                    'event': 'productClick',
                    'ecommerce': {
                        'click': {
                            'actionField': {'list':list},      // Optional list property.
                            'products': [prod]
                        }
                    },
                    'eventCallback': 'function() { document.location = url}',
                });
            }

        }
    }
}

/***** PROD ASSOCS DATA LAYERS *****/

function dataLayer_associated_product () {
    if (window.$('#gamme_product_carousel')) {

        var product_id = this.getAttribute('data-produit-id') ? event.currentTarget.getAttribute('data-produit-id') : '';
        var productObj = null;

        if (product_id !== '') {
            if ( data_layer_products.length > 0 ) {

                data_layer_products.forEach(function(item){
                    if ( item.id == product_id ) {
                        productObj = item;
                    }
                })
            }
            var prod = productObj;
            if (productObj !== null && productObj !== undefined ) {
                dataLayer.push({
                    'event': 'productClick',
                    'ecommerce': {
                        'click': {
                            'actionField': {'list': productObj['category']},      // Optional list property.
                            'products': [prod]
                        }
                    },
                    'eventCallback': "function () {document.location = productObj.url}"
                });
            }
        }
    }
}

function displayAllTextSeo () {
    var aReadmore = document.querySelector("a.readmore");
    var pSeo = document.querySelector(".seo_txt");
    var pSeoBrut = document.querySelector(".seo_txt_brut");
    aReadmore.style.display = "none"
    pSeo.style.display = "none"
    pSeoBrut.style.display = "block"
}

setTimeout(function(){
var tabLabelNewsletter = document.querySelectorAll(".load-newsletter")
if (tabLabelNewsletter.length > 0){
        for (var elem of tabLabelNewsletter) {
            elem.classList.toggle("load-newsletter");
        }
    }
}, 500);

/**
 * @description Changes the numbers in the wishlist pad
 * @author  Stefane DR  <sdr@wshop.com>
 * @since   2023-01-25
 * @param   {integer}   nb
 */
function changeWishlistNb(nb) {
    nb = parseInt(nb);

    $('#menu_account_wishlist .highlight').html('( ' + nb + ' )');
    if (nb == 0) {
        $('#menu_account_wishlist').addClass('cache');
    } else if ($('#menu_account_wishlist').hasClass('cache')) {
        $('#menu_account_wishlist').removeClass('cache').show();
    }

    // if we're on the "My Wishlist" page
    if ($('body.wishlist').length > 0) {
        if (nb == 0) {
            $('.selection_shopping_intro').removeClass('cache').show();
            $('#nrArticlesSelected').hide();
        } else if ($('.selection_shopping_intro').hasClass('cache')) {
            $('.selection_shopping_intro').addClass('cache');
            $('#nrArticlesSelected').show();
        }
    }
}

function updateColor() {
    var item_visu = document.getElementById('img_large');
    var color_visu = document.getElementById('preview_color');
    var item_url = $(this).attr('data-color-img');
    var color_url = path_relative_root + 'img/couleur/' + $(this).value + '.jpg';
    var color_hexa = $(this).attr('data-hexa');
    var is_model = $(this).attr('data-ismodel');
    var has_photo = $(this).attr('data-color-img');

    if (has_photo == '0') {
        has_photo = false;
    }

    if ($(this).val() != '' && color_visu) {
        if ($('#img_large.product_picture').length > 0) {
            $('#img_large.product_picture').each(function(index) {
                $(this).attr('src', item_url);
            });
        } else {
            item_visu.src = item_url;
        }

        color_visu.removeAttribute('style');

        if (color_hexa) {
            if (color_hexa.length > 0) {
                color_visu.setAttribute("style", "background-color : " + color_hexa);
            }
        } else {
            if (is_model) {
                color_visu.setAttribute("style", "background:url(" + item_url + ") center;background-size:cover");
            } else if (has_photo) {
                color_visu.src = has_photo;
                color_visu.setAttribute("style", "background:url(" + has_photo + ") ");
            } else {
                color_visu.setAttribute("style", "background:url(" + color_url + ") center");
            }
        }

        $('#prod_couleur .colors_list .form_itm').removeClass('checked');
        $(this).parent('.form_itm').addClass('checked');

        color_visu.css;
        $(color_visu).show();
    } else {
        $(color_visu).hide();
    }

    if ($("body").hasClass("product_page")) {
        checkProductInWishlist();
    }
}

if ($('.bandeau_menu_filter_swiper ').length) {
    if ($('.bandeau_menu_filter_swiper .swiper-slide').length > 3) {
        $('.swiper_btn_pagination_wrapper ').show();

        window.setTimeout(function () {
            var bandeau_menu_filter_swiper = new Swiper('.bandeau_menu_filter_swiper', {
                slidesPerView: 3,
                spaceBetween: 30,
                nextButton: '.bandeau_menu_swiper-button-next',
                prevButton: '.bandeau_menu_swiper-button-prev',
                scrollbarDraggable: true,
                scrollbarHide: false,
            });
        }, 100);
    } else {
        window.setTimeout(function () {
            var bandeau_menu_filter_swiper = new Swiper('.bandeau_menu_filter_swiper', {
                slidesPerView: 3,
                spaceBetween: 30,
                scrollbarHide: false,
            });
        }, 100);
    }
}

function addToWishlistRay() {
    addToWishlist.call(this);
}

/**
 * Dans la fiche produit. Le bouton ajouter à la wishlist. (product_right.php)
 */
function addToWishlist(event) {
    var btn = $(this);
    var idElt = $(this).attr('data-idelt');
    var productId = $(this).attr('data-productid');
    var wishlistProductId = $(this).attr('data-wishlistproductid');
    var productRef = $(this).attr('data-product-ref');
    var wishlist = [];

    if (typeof(productId) == 'undefined') {
        var productId = $("#hdn_product_id").val();
    }

    var colorId = btn.attr('data-product-color-id');
    if (colorId == '' || colorId == 0 || typeof(colorId) == 'undefined') {
        colorId = $('#couleurProd_' + idElt).length == 1 ? $('#couleurProd_' + idElt).val() : $('#couleurProd').val();
    }

    var sizeId = btn.attr('data-product-size-id');
    if (sizeId == '' || sizeId == 0 || typeof(sizeId) == 'undefined') {
        sizeId = $('#tailleProd_' + idElt).length == 1 ? $('#tailleProd_' + idElt).val() : $('#tailleProd').val();
    }

    var datas = {
        product_id: productId,
        color_id: colorId,
        size_id: sizeId,
        need_color_id: 'false',
        need_size_id: 'false'
    };

    if (!btn.hasClass('existToWishlistButton')) {
        $.ajax({
            url: path_relative_root + create_link('ajax_add_to_wishlist'),
            type: 'post',
            data: datas
        })
        .done(function (resp) {
            if (resp.error) {
                if (resp.result.error_message) {
                    alert(Translator.translate(resp.result.error_message));
                } else {
                    var message = resp.result.error_message ? resp.result.error_message : resp.message;
                    $(".disabledBtnTooltip").addClass('actif');
                    $(".wrap_btn_abox button span").html('ok');
                    if ($('.category').length > 0) {
                        alert(Translator.translate('error_wishlist_' + message.toLowerCase()));
                    }
                    return false;
                }
            } else {
                if (parseInt($('#in_basket').val()) === 1) {
                    var btn_wish = "#addToWishlistButton_" + product_id;
                    var span_wish = btn_wish + " span";
                    var del_product = "#del_product_" + product_id;

                    btn_wish = btn_wish.replace(',', '');
                    span_wish = span_wish.replace(',', '');
                    del_product = del_product.replace(',', '');

                    $(span_wish).html(resp.result.button_label);
                    $(btn_wish).attr("onclick", "").attr("href", path_relative_root + create_link('wishlist'));

                    $(del_product)[0].click();
                } else {
                    $("#addToWishlistButton span").html(resp.result.button_label);
                    btn.attr("data-wishlistproductid", resp.result.wishlist_product_id);
                    btn.addClass("existToWishlistButton");
                    wishlist.push(resp.result.wishlist_row_array);
                    changeBtnAddWishlist(true, productRef);
                }
            }
        });
    } else {
        $.ajax({
            url: path_relative_root + create_link('ajax_remove_from_wishlist'),
            type : 'post',
            data: {
                    id: wishlistProductId,
                    product_id: productId,
                    color_id: colorId,
                    size_id: sizeId
                }
        })
        .done(function(resp) {
            if (!resp.error) {
                btn.removeClass("existToWishlistButton");
                btn.attr('data-wishlistproductid', '0');
            }
        });
    }
}

// Infinite Scroll + List page scripts
function paginationGoToPage(page, fromScrollBottom, fromScrollTop) {

    page = page || 1;

    var type = document.getElementById('type_tri').value;
    var idObj = getCurrentIdRayon();
    var bloc_page = page - 1;

    /**
     * Mise à jour des valeurs de pages d'origine pour le scroll infini
     */
    if ($('#scroll_origin').length) {
        if (!fromScrollBottom && fromScrollTop) {
            $('#scroll_origin').val(page);
        } else if (fromScrollBottom && !fromScrollTop) {
            $('#initial_page').val(page);
        } else {
            $('#scroll_origin, #initial_page').val(page);
        }
    }

    if (from_type === 'product') {
        generateNewBlocProd(type, idObj, bloc_page, null, null, fromScrollBottom, fromScrollTop, true);
    } else {
        generateNewBlocSearch(document.getElementById('nb_prod').value, bloc_page, null, fromScrollBottom, fromScrollTop);
    }
}

function paginate(page) {

    var form = document.getElementById('filters_form'),
        total_element = parseFloat($('#totalElems').val()),
        nb_prod = parseFloat($('#nb_prod').val()),
        nb_total_page = Math.ceil(total_element / nb_prod),
        nb_prod_default = parseFloat($('#nb_prod_default').val()),
        pagination = $('.pager_wrapper'),
        see_all = parseFloat($('#see_all').val());

    page = page || 1;

    $.ajax({
        url: path_relative_root + 'ajax_reload_pagination.php',
        type: 'get',
        data: {
            page: page,
            link: 'paginationGoToPage()',
            nb_total_page: nb_total_page
        },
        success: function (res) {

            var productLoaded = $('#list_item .item_container:not(.push)').length;
            var percentLoaded = (productLoaded * 100 / total_element);

            pagination.each(function () {

                if (see_all === 0) {
                    this.innerHTML = res + '<div class="nbProdDisplayed">' + Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + total_element + '</div><div class="loadedGauge"><span style="width: ' + percentLoaded + '%;"></span></div><div class="see_all_product">' + Translator.translate('see_more_products') + '</div>';
                } else if (see_all === 1) {
                    this.innerHTML = '<div class="nbProdDisplayed">' + Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + total_element + '</div><div class="loadedGauge"><span style="width: ' + percentLoaded + '%;"></span></div><div class="see_all_product">' + Translator.translate('see_x_product_by_page', nb_prod_default) + '</div>';
                } else {
                    this.innerHTML = '';
                }
            });

            if (productLoaded == total_element) {
                $('.see_all_product').hide();
            }
        }
    });
}

function seeXProduct(nb_product, page) {

    $('.see_all_product').addClass('loading');
    var nb_prod_default = parseFloat($('#nb_prod_default').val());

    // To display everything, start from the current page
    if (nb_product === (nb_prod_default + 1)) {
        $('#scroll_origin').val($("#initial_page").val());
        $("#see_all").val("1");
        generateNewBlocProd(null, null, (page - 1), null, null, false, true, true);
        $("#initial_page").val(page);

    } else {
        $('#scroll_origin').val($("#initial_page").val());
        $("#see_all").val("1");

        // If we’re on the search page
        if ($("#search_page").length > 0) {
            generateNewBlocSearch(0);
        } else {
            generateNewBlocProd(null, null, 0, null, null, false, true, true);
            $("#initial_page").val(page);
        }
    }
}

function generateNewBlocProd(type, idObj, page, nb_product, btn, fromScrollBottom, fromScrollTop, fromPagination = false) {

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filters_form'),
        page = (page !== undefined) ? page : form.getValue('page', parseFloat),
        type = type || form.getValue('type'),
        idObj = idObj || form.getValue('idObj'),
        nb_product = nb_product || form.getValue('nb_prod', parseFloat),
        startPage = parseInt($('#page_start').val()),
        is_from_page = false,
        key,
        output = {};

    if ($('.see_all')) {
        $('.see_all').addClass('loading');
    }

    output = triggerFilter(page, type, idObj, nb_product, 'filters_form', fromPagination);
    nb_product = nb_product || parseFloat($('#nb_prod').val());

    if (nb_product !== undefined) {
        this.nb_prod.value = nb_product;
    } else {
        nb_product = this.nb_prod.value;
    }

    if (page !== undefined && page !== 'all') {
        this.page.value = page * nb_product;
        is_from_page = true;

    }

    if ($('#oneProduct').hasClass('actif')) {
        var one_product = true;
    } else {
        var one_product = false;
    }

    if (isNaN(this.page.value)) {
        this.page.value = 0;
    }

    // Add misc values to output object
    output.page = this.page.value;
    output.type = type;
    output.parentPath = path_relative_root;
    output.idObj = idObj;
    output.nb_prod = nb_product;
    output.is_page = is_from_page;
    output.nb_tot_prod = this.totalElems.value;
    output.current_page = page + 1;

    if (typeof id_category_super != 'undefined') {
        output.id_category_super = id_category_super;
    }

    if ($('#is_marque').val()) {
        output.is_marque = $('#is_marque').val();
        output.promo = $('#brand_id').val();
    }

    // Si le prix existe
    if ($("input[name=price_min]").length > 0) {
        // Récupération du prix
        output.prix =
            $("#amount1").val() +
            "~" +
            $("#amount2").val();
    }

    if ($("input[name='tri']:checked").length > 0) {
        output.tri = $("input[name='tri']:checked").val();
    }

    if (page === 'all') {
        /** LEGACY **/
        hash = location.hash.substr(1);
        if ((/i=[0-9]+/).test(hash)) {
            // Vu que le header est en absolute on le sort du calcul
            var marginTop = $('.banner_wrapper').height() + 10,
                $elem = $('#' + hash.replace('=', '')),
                offset = $elem.offset(),
                top = offset && offset.top - marginTop;
        }

        /** END LEGACY **/
    } else {
        if (bloc_prod_xhr) {
            bloc_prod_xhr.abort();
        }

        bloc_prod_xhr = $.ajax({
            url: path_relative_root + create_link('ajax_reload_products'),
            type: 'get',
            data: output,
            success: function (res) {

                var old_elm = document.querySelector('.list_item');
                var content, itm_length;

                if (btn && btn.nextElementSibling && (btn.nextElementSibling.classList.contains('btn_tunnel') || btn.nextElementSibling.classList.contains('loader'))) {
                    btn.nextElementSibling.style.display = 'none';
                }

                res = res.split('><><');

                content = res[0].trim();

                itm_length = parseFloat(res[1]);

                if ($('.rayonCategSlider').length && output.object_id) {

                    $('.rayonCategSlider .menuitem').removeClass('actif');
                    $(".menuitem[data-idobj='" + idObj + "']").addClass("actif");

                    var rayonInitialSlide;

                    $('.rayonCategSlider .swiper-slide').each(function (i, obj) {
                        if ($(obj).hasClass('actif')) {
                            rayonInitialSlide = i;
                        }
                    });

                    rayonCategSlider.slideTo(rayonInitialSlide);
                } else {
                    $('.rayonCategSlider .menuitem').removeClass('actif');
                }

                // This block of code updates the SEO-related link elements in the HTML document.
                let canonical = res[5];
                let prev = res[6];
                let next = res[7];

                $("link[rel^='canonical']").attr("href", path_root + canonical);
                $("link[rel^='alternate'][hreflang^='fr-fr']").attr("href", path_root + '/' + canonical);

                $("link[rel^='alternate'][hreflang^='en']").attr("href", JSON.parse(res[8])[8].en);
                $("link[rel^='alternate'][hreflang^='es-es']").attr("href", JSON.parse(res[8])[7].es);
                $("link[rel^='alternate'][hreflang^='de-de']").attr("href", JSON.parse(res[8])[9].de);
                $("link[rel^='alternate'][hreflang^='it-it']").attr("href", JSON.parse(res[8])[10].it);

                let hrefPrev = $("link[rel^='prev']");
                let hrefNext = $("link[rel^='next']");
                let commentPrev = $('#href_prev');
                let commentNext = $('#href_next');

                changeSeoElement(prev, hrefPrev, commentPrev, 'prev');
                changeSeoElement(next, hrefNext,commentNext, 'next');

                if (content !== '') {
                    if (!isNaN(page)) {
                        old_elm.setAttribute('data-p', (page * nb_product));
                    }

                    if (fromScrollBottom || fromScrollTop) {
                        $(".loader_scroll").fadeTo("fast", 0);
                    } else {
                        old_elm.innerHTML = "";
                    }

                    document.getElementById('nbr_items').innerHTML = itm_length;
                    document.getElementById('totalElems').value = itm_length;

                    paginate(page + 1);

                    if (fromScrollTop) {
                        var firstMsg = $('#scroll_items .item_container:first');
                        var curOffset = firstMsg.offset().top - $(document).scrollTop();
                        old_elm.insertAdjacentHTML('afterbegin', content);
                        $(document).scrollTop(firstMsg.offset().top - curOffset);
                        //L'inverse pour le bas
                    } else {
                        old_elm.insertAdjacentHTML('beforeend', content);
                    }

                    if (one_product) {
                        $('div.item_container').addClass('full');
                    }

                    if (parseFloat($('#filters_menu').css('right')) === 0) {
                        toggleMenu('filters', 'right');
                    }

                    if (window.lazyload || typeof lazyload != 'undefined') {
                        lazyload.init();
                    }

                    if (typeof lazyloadImage === 'function') {
                        lazyloadImage();
                    }
                } else {
                    old_elm.innerHTML = '';
                    document.getElementById('totalElems').value = itm_length;
                }
            },
            complete: function () {
                $('#totalElems').trigger('change');
                $('#bottom_reached').val("0").trigger('change');
                $('#page').trigger('change');

                if (!fromScrollBottom && !fromScrollTop) {
                    //reset initiialPage if from filter
                    $("#initial_page").trigger("change");

                }

                if (fromScrollTop || fromScrollBottom) {
                    $('#is_loading').val('0');
                }

                if (fromScrollBottom && !fromScrollTop) {
                    $("#page_start").val(startPage + 1);

                    if ($("#page_start").val() >= 1) {
                        $("#initial_page").val(page);
                    }
                }

                setTimeout(function () {
                    var loader = $('.button + .loader');
                    if (loader.hasClass('loading')) {
                        loader.removeClass('loading');
                    } else {
                        loader.parent().removeClass('loading');
                    }

                    if ($(btn).closest('.drawer').length && $(btn).closest('.drawer').attr('id')) {
                        var menuId = $(btn).closest('.drawer').attr('id').replace('_menu', '');
                        toggleMenu(menuId);
                    }
                }, 200);

                /**
                 * UPDATE BOTTOM GAUGE WIDTH NB PRODUCTS LOADED / NB PRODUCTS TOTAL + BTN INITIAL STATE (No loader)
                 */
                var productLoaded = $('#scroll_items .item_container:not(.push)').length;
                var percentLoaded = (productLoaded * 100 / parseInt(output.nb_tot_prod));

                if (productLoaded > output.nb_tot_prod) {
                    $('.nbProdDisplayed').html(Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + productLoaded);
                } else {
                    $('.nbProdDisplayed').html(Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + parseInt(output.nb_tot_prod));
                }

                $('.txt_bandeau label').html('(' + output.nb_tot_prod + ' ' + Translator.translate('2_product') + ' )');

                $('.loadedGauge').html('<span style="width: ' + percentLoaded + '%;"></span>');

                $('.loader_scroll').removeClass('loading');

                if (productLoaded >= document.getElementById('totalElems').value) {
                    $('.loader_scroll').hide();
                    $('.see_all_product').hide();
                }

                let itm_length = document.getElementById('totalElems').value ;
                document.getElementById('number_product').innerHTML = itm_length;
                $("#filters_form_submit span").html(Translator.translate("apply_item_filters"));
                $("#number_product_trad").html(itm_length > 1 ? Translator.translate("x_products_mobile") : Translator.translate("x_product_mobile"));
            }
        });
    }
}

function generateNewBlocFilter(type, idObj, page, nb_product, btn, typeFiltre, id) {
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filters_form'),
        output = {},
        tmpoutput = {},
        is_from_page = false,
        key;

    var thisform = form;
    // Get all filters
    form = Array.prototype.slice.call(form.elements, 0);
    var keys = new Array();

    if (use_new_filter_url) {
        output = triggerFilter(page, type, idObj, nb_product, 'filters_form');

    } else {
        // Build an object to store active filters
        form.forEach(function (elm) {
            if ((elm.type === 'checkbox' || elm.type === 'radio')) {

                if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {

                    if (output[elm.name] === undefined) {
                        output[elm.name] = [];
                    }
                    output[elm.name].push(parseFloat(elm.value));
                }
                keys[elm.name] = 1;
            }
        });
        // Convert filters arrays to strings
        for (key in output) {

            if (output.hasOwnProperty(key)) {

                if (output[key].constructor === Array) {

                    output[key] = output[key].join('~');
                }
            }
        }
        if ($(".filter_date").length > 0) {

            output.multi_object = 'true';
            output.id_category_super = id_category_super;

            if (typeof (output['date']) == 'undefined') {
                output.date = 4;
            } else {
                if (output['date'] == 4)
                    output['date'] = tmp_date;
            }
        } else {
            if (output['date'] == 4) {
                output['date'] = tmp_date;
            }
        }

        if (nb_product !== undefined) {

            this.nb_prod.value = nb_product;
        } else {

            nb_product = this.nb_prod.value;
        }

        // Add misc values to output object
        output.page = this.page.value;
        output.type = type;
        output.parentPath = path_relative_root;
        output.idObj = idObj;
        output.nb_prod = nb_product;
        output.is_page = is_from_page;
        output.nb_tot_prod = this.totalElems.value;
        output.typeFiltre = typeFiltre;

    }

    /** LEGACY **/

    if (bloc_prod_xhr) {
        bloc_prod_xhr.abort();
    }

    bloc_prod_xhr = $.ajax({
        url: path_relative_root + 'ajax_change_filtre_produit.php',
        type: 'get',
        data: output,
        success: function (response) {

            var resultats = jQuery.parseJSON(response);

            var typeFilter = resultats.filter;
            var array_size = resultats.size;
            var array_colors = resultats.color;
            var array_brand = resultats.brand;
            var array_eclat = resultats.eclat;
            var array_store = resultats.store;

            if (typeFilter == 'sscat' && $('.container_products_lookbook').length == 0) {
                if (array_colors.length == 0 || array_size.length == 0) {
                    changeajaxfilterRayon(thisform, 'all', id, true);
                    return true;
                }
            }

            if (typeFilter != 'taille') {
                generateListFilter('taille', array_size, true);
            }

            if (typeFilter != 'couleur') {
                generateListFilter('couleur', array_colors, true);
            }

            if (typeFilter != 'brand') {
                generateListFilter('promo', array_brand, true);
            }

            if (typeFilter != 'eclat') {
                generateListFilter('eclat', array_eclat, true);
            }

            if (typeFilter != 'store') {
                generateListFilter('store', array_store, true);
            }

            /* Nombre de produits */
            // Get all filters
            form = Array.prototype.slice.call(thisform.elements, 0);
            // Build an object to store active filters
            form.forEach(function (elm) {
                if ((elm.type === 'checkbox' || elm.type === 'radio')) {

                    if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {

                        if (tmpoutput[elm.name] === undefined) {
                            tmpoutput[elm.name] = [];
                        }

                        if (elm.name == 'charact') {
                            tmpoutput[elm.name].push(elm.value);
                        } else {
                            tmpoutput[elm.name].push(parseFloat(elm.value));
                        }
                    }
                }
            });

            // Convert filters arrays to strings
            for (key in keys) {
                if (tmpoutput.hasOwnProperty(key)) {
                    output[key] = tmpoutput[key].join('~');
                } else {
                    delete output[key];
                }
            }

            if ($(".filter_date").length > 0) {

                output.multi_object = 'true';
                output.id_category_super = id_category_super;

                if (typeof (output['date']) == 'undefined') {
                    output.date = 4;
                } else {
                    if (output['date'] == 4)
                        output['date'] = tmp_date;
                }
            } else {
                if (output['date'] == 4) {
                    output['date'] = tmp_date;
                }
            }

            bloc_prod_xhr = $.ajax({
                url: path_relative_root + 'ajax_reload_products.php',
                type: 'get',
                data: output,
                success: function (res) {
                    var new_elm = document.createElement('div'),
                        old_elm = document.getElementById('scroll_items'),
                        content, itm_length;

                    res = res.split('><><');

                    content = res[0].trim();

                    if (res[1] !== '') {
                        itm_length = parseFloat(res[1]);
                        
                        // Allows refreshing the total number of remaining products after applying a filter
                        document.getElementById('number_product').innerHTML = itm_length;
                        document.getElementById('totalElems').value = itm_length;
                        $("#filters_form_submit span").html(Translator.translate("apply_item_filters") + " (" + itm_length + ")");
                    }
                    $('#is_loading').val('0');
                }
            });
        }

    });
}

function generateNewBlocSearch(nb_product, page, btn, fromScrollBottom, fromScrollTop) {

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filters_form'),
        output = {},
        is_from_page = false,
        key;

    if (page == 0) {
        if (form) {
            form.initial_page.value = page + 1;
            form.scroll_origin.value = page + 1;
        } else {
            $('#filters_form #initial_page').val(page + 1)
            $('#filters_form [name="scroll_origin"]').val(page + 1)
        }
    }

    Array.prototype.slice.call(form.elements).forEach(function (elm) {
        if ((elm.type === 'checkbox' || elm.type === 'radio')) {
            if (elm.checked && elm.value !== '') {
                if (output[elm.name] === undefined) {
                    output[elm.name] = [];
                }
                output[elm.name].push(elm.value);
            }
        } else if (elm.type === 'hidden') {
            if (elm.value !== '') {
                output[elm.name] = elm.value;
            }
        }
    });

    if (fromScrollTop || fromScrollBottom) {
        $(".loader_scroll").css({
            display: 'block'
        });
        $('#is_loading').val('1');
    }

    for (key in output) {
        if (output.hasOwnProperty(key)) {
            if (output[key].constructor === Array) {
                output[key] = output[key].join('~');
            }
        }
    }

    if (nb_product !== undefined) {
        form.nb_prod.value = nb_product;
    } else {
        nb_product = form.nb_prod.value;
    }

    if (page !== undefined && page !== 'all') {

        form.page.value = output.page = page * nb_product;
        is_from_page = true;
    }

    output.parentPath = path_relative_root;
    output.nbProducts = nb_product;
    output.is_page = is_from_page;

    // Si le prix existe
    if ($("input[name=price_min]").length > 0) {
        // Récupération du prix
        output.prix =
            $("#amount1").val() +
            "~" +
            $("#amount2").val();
    }

    $.ajax({
        url: path_relative_root + create_link('ajax_reload_search'),
        type: 'get',
        data: output,
        success: function (res) {

            var new_elm = document.createElement('div'),
                old_elm = document.querySelector('.list_item'),
                content, itm_length;

            if (btn && btn.nextElementSibling && (btn.nextElementSibling.classList.contains('btn_tunnel') || btn.nextElementSibling.classList.contains('loader'))) {

                btn.nextElementSibling.style.display = 'none';
            }

            res = res.split('><><');

            content = res[0].trim();
            itm_length = parseFloat(res[1]);

            if (content !== '') {

                if (!fromScrollBottom && !fromScrollTop) {
                    new_elm.className = 'list_item';
                    new_elm.innerHTML = content;
                    old_elm = document.querySelector('#scroll_items');
                    $(old_elm).find('list_item').remove();
                }

                if (!isNaN(page)) {
                    old_elm.setAttribute('data-p', (page * nb_product));
                }

                if (fromScrollBottom || fromScrollTop) {
                    $(".loader_scroll").show();
                } else {
                    backToTop();
                    old_elm.innerHTML = "";
                }

                document.getElementById('nbr_items').innerHTML = itm_length;
                document.getElementById('totalElems').value = itm_length;

                paginate(page + 1);

                if (!fromScrollBottom && !fromScrollTop) {
                    old_elm.appendChild(new_elm);
                }

                if (fromScrollTop) {
                    var firstMsg = $('#scroll_items .item_container:first');
                    var curOffset = firstMsg.offset().top - $(document).scrollTop();
                    old_elm.insertAdjacentHTML('afterbegin', content);
                    $(document).scrollTop(firstMsg.offset().top-curOffset);
                    //L'inverse pour le bas
                } else if (fromScrollBottom) {
                    old_elm.insertAdjacentHTML('beforeend', content);
                }

                /** LEGACY **/

                var js_compteur = $('.vf_countdown').length;
                var js_encart = content.match(/\/\/vf_begin([\s]+[\w$() {}\[\]=;\0,\'\".:\/<&!+% ]*)+\/\/vf_end/g);

                if (js_compteur != null) {
                    try {
                        var cnt_compteur = js_compteur.length;

                        for (var i = 0; i < cnt_compteur; i++) {
                            eval(js_compteur[i].replace(/(vf_compteur_begin|vf_compteur_end)/g, ""));
                        }
                    } catch (e) {
                    }
                }
                if (js_encart != null) {
                    try {
                        js_encart = js_encart[0].replace(/(\/\/vf_begin|\/\/vf_end)/g, "");
                        eval(js_encart);
                    } catch (e) {
                    }
                }

                /** END LEGACY **/

                if (parseFloat($('#filters_menu').css('right')) === 0) {

                    toggleMenu('filters', 'right');
                }

                if (window.lazyload) {

                    lazyload.init();
                }

                if (typeof lazyloadImage === 'function') {
                    lazyloadImage();
                }
            }
        },
        complete : function () {
            $('#totalElems').trigger('change');
            $('#bottom_reached').val("0").trigger('change');
            $('#page').trigger('change');
            $(".loader_scroll").fadeTo("fast", 0);

            setTimeout(function () {

                var loader = $('.button + .loader');

                if (loader.hasClass('loading')) {

                    loader.removeClass('loading');
                } else {

                    loader.parent().removeClass('loading');
                }

                var menuId = $(btn).closest('.drawer').attr('id').replace('_menu', '');

                toggleMenu(menuId);
            }, 200);

            if (fromScrollTop || fromScrollBottom) {
                $('#is_loading').val('0');

                /**
                 * Ajout d'une min-height sur les blocs image rayon. Permet d'éviter l'effet de
                 * clignotement de la page (du au lazyload) si on scrolle depuis le bas.
                 */

                if ($('body.category').length) {
                    var origin = $('.item_container:not(.push)').find('img.watched'),
                        target = $('span.ill_img'),
                        targetHeight = origin.height();

                    target.each( function() {
                        $(this).css({minHeight: targetHeight});
                    })
                }
            }

            if (fromScrollBottom && !fromScrollTop) {
                $("#initial_page").val(page +1);
                $(".loader_scroll").hide();
            }
        }
    });
}
// END Infinite Scroll + List page scripts

alert = (function() {
    "use strict";

    var shade, albox, alert_tmpl;
    var regex = new RegExp("\\s", "g");

    function init() {
        albox = document.getElementById("alert_box");
        alert_tmpl = albox.innerHTML.trim();
        window.removeEventListener("load", init);
    }

    function closeAlertBox() {
        closeMultiShad('alert_box');
    }

    function alert(str) {

        $(".alert_box_content", albox).html(str);
        $(".close", albox).on("click", closeAlertBox);

        openMultiShad('alert_box', 'bottom');
    }

    window.addEventListener("load", init);

    return alert;
})();

$('#shade').on('click', function() {
    $('#filters_menu').removeClass('open');
    setTimeout(function () {
        $('#wrapper_tri_filtre legend').removeClass('open');
        $('#wrapper_tri_filtre .filter_options').css('display','none');
    }, 500);
});

/* PRICE FILTER RESET WITH SEARCH */

// Initialization of slider range min and max value
var price_min = $('#amount1').val();
var price_max = $('#amount2').val();

function changeFiltre(form, reset) {
    var value = getInputVal.bind(form),
        id = value('type_nav', 'tous');
    var type = value('type_tri'),
        idObj;
    var btn = this,
        idObjTmp;    

    // Get all filters
    const items = document.querySelectorAll('.formes_container .option');
    // Remove selected class from all items
    items.forEach(item => { 
            item.classList.remove('selected');
    });

    // If form must be reset
    if (reset) {
        if (filterformColors && filterformSize) {
            document.getElementById('wrapper_couleur_filtre').innerHTML = filterformColors;
            document.getElementById('wrapper_taille_filtre').innerHTML = filterformSize;
        }
        form.reset();
        $('#filters_menu input[checked]').not('#wrapper_sscat_filtre input[checked]').removeAttr("checked"); //force suppression de tous les filtres checked (sauf ss-cat), ce qui arrive lorsqu'on revient d'une FP
        // On fait un reset des filtres pour récupérer toutes les tailles et toutes les couleurs
        // Commented due to unwanted behavior, we need only form.reset() to reset filter form, we don't need to load all the sizes and colors from database so we keep the default.
        $('#amount1').val(price_min);
        $('#amount2').val(price_max);
        $('.'+$(this).attr('data-obj_id')).click();

        // reset to min/max values
        $('.ui-slider-handle:first').html(price_min  + '<span class="currency">' + devise + '</span>' );
        $('.ui-slider-handle:last').html(price_max + '<span class="currency">' + devise + '</span>');
        // repositioning the min/max values on slider
        $("#slider-range").slider("option", { min: parseInt(price_min), max: parseInt(price_max), values: [parseInt(price_min), parseInt(price_max)], });
    }

    $('legend.open', form).each(function() {

        toggleFiltersMenu.call(this);
    });

    // Check obj type
    if (form.sscat) {

        // Attention ce n'est pas des sscat mais des bien objets (views_mobile_new/wrapper_filters.php)
        idObjTmp = value('sscat');

        if (idObjTmp && idObjTmp.length > 0) {

            type = 'obj';
            $('#object_id').val(idObjTmp);

        } else {

            type = 'subcat';
        }
    }

    $('#type_tri').val(type);

    idObj = getCurrentIdRayon();

    // Display loader
    if (btn.nextElementSibling && btn.nextElementSibling.classList.contains('button')) {
        btn.nextElementSibling.style.display = 'block';
    }
    

    // Get new elements
    if (from_type == 'product') {
        generateNewBlocProd.call(form, type, idObj, 0, value('nb_prod'), btn);
    } else {
        generateNewBlocSearch.call(form, value('nb_prod'), 0, btn);
    }

    $('body').removeClass('search_hidden');
    $('.bandeauMenuAffix').css('opacity','1');
    $('html, body').animate({ scrollTop: 0 }, 'slow');

    // Prevent default action
    return false;

}

// Toggles filters submenu visibility
function toggleFiltersMenu() {
    var open = $(this).closest('li').siblings('li').find('legend.open').get(0);
    this.classList.toggle('open');
    
    var $submenu = $(this).next('ul');
    
    if (this.classList.contains('open')) {
        // If the parent li has the class 'formes_container'
        if ($(this).closest('li').hasClass('formes_container')) {
            // Ensure the element is hidden, force the display to flex, then animate the opening
            $submenu.hide().css('display', 'flex').slideDown(400);
        } else {
            $submenu.slideDown(400);
        }
    } else {
        $submenu.slideUp(400);
    }
    
    if (open) {
        open.classList.remove('open');
        $(open).next('ul').slideUp(400);
    }
}


function changeVisu() {}

/**
 * Display lightbox contact store
 */
function showLightboxContactStore() {

    $('.w-input-element').attr('value','');

    var shad = document.getElementById('shad');
    var modbox = document.getElementById('pdvStoreBox');

    $('html, body').animate({
        scrollTop: 0
    }, 500);

    $(modbox).addClass('actif');
    $(shad).addClass('actif');
    $(shad).on('click', closeLightboxContactStore);

    setTimeout(function () {
        $('.inUse').attr('value','');
        $('.clientPhone').intlTelInput();
    }, 400);
}

/**
 * Close lightbox contact store
 */
function closeLightboxContactStore() {
    $('.modal_shade').removeClass('visible');
    $('#ligthbox_send_store_information').slideUp("slow").queue(function() {
        $('#shade').stop(true, true).fadeTo("slow", 0, function() {
            document.getElementById('ligthbox_send_store_information').style.display = 'none';
        });
        document.getElementById('shade').style.display = 'none';
        $('#ligthbox_send_store_information').dequeue();
    });
}

// Function to check if required inputs in form are filled, otherwise add class error
function checkRequiredInputs (el) {
    el != undefined ? el = $(el) : el = $('.w-contact-form');

    var requiredFields = el.find('[required]');
    var isValid = true;

    requiredFields.each(function () {
        var input = $(this);

        if (input.val() === '') {
            input.addClass('inputErr');
            isValid = false;
        } else {
            if (input.hasClass('inputError')) {
                input.removeClass('inputErr');
            }
        }
    });

    return isValid;
}

/**
 * Send request contact to store
 */

function sendFormContactByStore(formulaire) {
    formulaire != undefined ? formulaire = formulaire : formulaire = document.querySelector('.w-contact-form');

    var optin_g = ($("input[name='optin']:checked").val());
    var optin_sms = ($("input[name='optin_sms']:checked").val());
    var optin_partners = ($("input[name='optin_partners']:checked").val());

    if (checkRequiredInputs()) {
        var data = {
            'produit_id': formulaire.produit_id.value,
            'produit_url': formulaire.produit_url.value,
            'client_nom': formulaire.nom.value,
            'client_prenom': formulaire.prenom.value,
            'client_email': formulaire.email.value,
            'client_telephone': formulaire.tel.value,
            'client_message': formulaire.message.value,
            'magasin_id': formulaire.magasin_id.value,
            'optin_general': optin_g,
            'optin_sms': optin_sms,
            'optin_partners': optin_partners
        };

        $.ajax({
            url: path_relative_root + create_link('ajax_contactbystore'),
            type: 'post',
            data: data,
            async: false,
            success: function (res) {
                if ($('.formStore .valide').length) {
                    $('.formStore .block-content').hide();
                    $('.formStore .valide').show();
                    $('.formStore .valide').html(JSON.parse(res))
                    $('.formStore').addClass('thanks');
                }

                if ($('#content_pdvBox_new').length) {
                    $('#content_pdvBox_new').html(JSON.parse(res))
                    $('#pdvStoreBox').addClass('thanks');
                }

            }
        });
        return false; //prevent submit
    }
}

$(document).ready(function() {
    if ($("body.satellite_store_details #content_pdvBox_new").length || $("body.product_page #contact_mag_component").length) {
        var el = $("#content_pdvBox_new #w-contact-form, #contact_mag_component #formMag");

        if(!checkContactFormOnBlur(el)) {
            return false;
        }

        el.find(".btn_form_contact").click(function() {
            $('.btn_form_contact').next('.loader').addClass('loading');

            setTimeout(function() {
                sendFormContactByStore();
            }, 500)

            window.setTimeout(function() {
                $('.btn_form_contact').next('.loader').removeClass("loading");
            }, 2000);

            if (checkContactFormOnClick (el)) {
                return true;
            } else {
                return false;
            }
        });
    }
});

function form_animatePlaceholder(form) {
    if ($(form).length) {
        var inputFields = $(form).find('input, textarea');

        inputFields.each(function () {
            var input = $(this);
            var placeholder = input.parent().find('.placeholderTxt');

            input.on('focus', function () {
                input.addClass('inUse');
                placeholder.addClass('inUse');

                if (input.hasClass('clientPhone')) {
                    input.parent().next(".placeholderTxt").addClass('inUse');
                }
            });

            input.on('blur', function () {
                if (input.val() === '') {
                    placeholder.removeClass('inUse');

                    if (input.hasClass('clientPhone')) {
                        input.parent().next(".placeholderTxt").removeClass('inUse');
                    }
                }
            });
        });

    }
}

// Zooms in item main visual
function zoomImg() {
    var container = $(this).closest('#zoom_container'),
        modbox = $('#zoomBox'),
        shad = $('#al'),
        colors = $('#autres_couleurs img', container),
        visuals = $('#autres_vues img', container),
        col_tgt = $('.col', modbox),
        vis_tgt = $('.vis', modbox);

    col_tgt.html('');
    vis_tgt.html('');

    // fix zoom on FP
    if ($('ul#product_pictures_slider li').length > 1) {
        // replace prodfp by medium to have the good image quality
        zoomUpdateMain($('.swiper-slide-active img', container).attr('src').replace('/medium/', '/large/'));
    } else {
        zoomUpdateMain($('.swipe_slider_visu img', container).attr('src').replace('/prodfp/', '/large/'));
    }

    if (colors.length === 0) {
        col_tgt.parent().hide();
    } else {
        colors.each(zoomAddCol);
    }

    if (visuals.length === 0) {
        vis_tgt.parent().hide();
    } else {
        visuals.each(zoomAddVis);
    }

    shad.unbind('click').one('click', closeModalBox.bind(modbox));

    $(modbox).add(shad).show();
}

// EN CAS DE RECHERCHE
function codeAdresse(id, flag) {
    if (flag != undefined) {
        var searchElem = document.getElementById('search_cp_' + flag);
        id = 'form_search_' + flag;
    } else {
        var searchElem = document.getElementById('search_cp');
        id = 'form_search';
    }
    var adresse = searchElem.value;

}

function loadValidationMessageTranslation(id) {
    // *********Verification for International & FR mobile phone number on Formulaire de Contact Error Message********* \\
    $( ".form_" + id + " #btn_contact" ).on( "click touch tap", function () {
        var textfield = $('.formStore.form_' + id + ' .w-input.w-text-input input[type="tel"]').get( 0 );
        textfield.setCustomValidity( '' );

        if ( !textfield.validity.valid ) {
            textfield.setCustomValidity(Translator.translate( 'login_erreur_12' ) );
        }
    });
}

function creerLigneListe(cpt, id, nom, adresse, ville, cp, pays, currently_open, horaire_today, distance, marqueur, picture_filtre, magasinId, stock, stockStatus, stock_quantity, clientId, favorite, beContacted, flag_available, url, shop_filter, telephone, query, lat, lng,) {
    currently_open = parseFloat(currently_open);
    var li = document.createElement('li');
    li.id = id;

    li.className = 'elem-list-store';

    if (lat != "" && lng != "" && !$('body.product_page').length) {
        li.setAttribute("onclick", "geolocGoto('" + lat + "', '" + lng + "')");
    }

    /***** Product stock infos *****/
    if (stock_quantity == 'undefined' || stock_quantity == '') {
        stock_status_msg = 'unknow_stock';
    } else {
        var in_stock = (stock_quantity >= 6 && stock_quantity <= 9999);
        var limited_stock = (stock_quantity >= 1 && stock_quantity <= 5);
        var not_in_stock = stock_quantity == 0;
        var to_be_confirmed_stock = stock_quantity == 10000;
        var can_be_ordered_stock = stock_quantity == 11000;

        var stock_status_msg = '';
        if (in_stock) {
            stock_status_msg = 'in_stock';
        }
        if (limited_stock) {
            stock_status_msg = 'limited_stock';
        }
        if (not_in_stock) {
            stock_status_msg = 'not_in_stock';
        }
        if (to_be_confirmed_stock) {
            stock_status_msg = 'to_be_confirmed_stock';
        }
        if (can_be_ordered_stock) {
            stock_status_msg = 'can_be_ordered_stock';
        }
    }

    var info_horaire_today = !horaire_today ? Translator.translate("store_closed") : horaire_today;
    var class_horaire_today = currently_open ? 'store_open' : 'store_closed';
    var ligneListe =
        '<div class="elem_list_contents">' +
        '<div class="nom_store">';
        if (distance) {
            ligneListe += '<h2 class="title">' + nom +  '<p class="distance">' + '('+ distance + 'km)' + '</p></h2>';
        } else {
            ligneListe += '<h2 class="title">' + nom + '</h2>';
        }
        ligneListe += '</div>' +
        '<div class="content_store">'

        if ($('body.product_page').length) {
            ligneListe += `<div class="detail_store" onclick="location.href = '${path_relative_root + url}'">`
        } else {
            ligneListe += '<div class="detail_store">'
        }
        ligneListe += '<div class="col-1">' +
        '<span class="rue_store">' + adresse.toLowerCase() + '</span>' +
        '<span class="ville_store">' + cp + ' ' + ville.charAt(0).toUpperCase() + ville.slice(1).toLowerCase() + ' - ' + '<span class="pays_store">' + pays + '</span>' + '</span>' +
        '<span class="tel_store">' + telephone + '</span></div>';

    var is_page_product = $('body.product_page').length == 1;

    if ((info_horaire_today !== '' && currently_open) || (!currently_open && horaire_today)) {
        ligneListe += '<div class="col-2"><p class="horaire_today puce_' + class_horaire_today + '">' + info_horaire_today + '</p></div>';
    }

    ligneListe += '</div>';
    if( document.getElementById('produit_id') ) {

        /***** Product stock infos msg *****/
        if (stock != 'undefined') {
            ligneListe += '<p class="stock_status ' + stock_status_msg + '">' + Translator.translate(stock_status_msg + '_msg') + '</p>';
        }
    }
    if (is_page_product) {

        if (beContacted == 1) {
            var contactStoreTxt = Translator.translate('contact_store');
            if ($('body.product_page.mobile').length) {
                ligneListe += '<div class="availableTxt store_more" onclick="handleChangeMobile('+ id +')">' + '<span>' + contactStoreTxt + '</span>' + '</div>' + '</div>' + '</div>';
            } else {
                var nom_mag = nom.replace(/'/g, "\\'");
                ligneListe += '<div class="availableTxt store_more" onclick="handleChange(\''+ id +'\',\''+ nom_mag + '\')">' + '<span>' + contactStoreTxt + '</span>' + '</div>' + '</div>' + '</div>';
            }
        } else {
            ligneListe+= '</div>' + '</div>';
        }
    }

    if (!is_page_product) {
        ligneListe += '<div class="store_more"><a onclick="createDataLayer(' + id + ')" href="' + path_relative_root + url + '">' + Translator.translate('afficher_horaires') + '</a>'
        + '</div>';
    }

    ligneListe+= '</div>' + '</div>' + '</div>';

    li.innerHTML = ligneListe;
    return li;
}

var removePaysRequest = true

/* ADD BUTON VOIR PLUS AFTER flowbox-container */
$( document ).ready( function () {
    var flowboxBtn = "<div id='flowboxbtn_voirplus'><a class='flowboxbtn_voirplus' href='" + path_relative_root + create_link( 'inspiration_flowbox' ) + "'>" + Translator.translate( 'voir_plus' ) + "</a></div>";
    setTimeout( () => {
        if($("body.homepage #flowbox-container")) {
            $(flowboxBtn).insertAfter("body.homepage #flowbox-container");
        }
    }, 500 );

    if ($("body.product_page").length ) {
        var load_block = true;

        $("body").on('DOMSubtreeModified', ".wrapper_store", function () {
            if (load_block && $('#clientTel').length ) {
                $(".wrapper_retailers_map .formStore").each(function () {
                    var el = $(this).find("#formMag");

                    if(!checkContactFormOnBlur(el)) {
                        return false;
                    }

                    el.find("#btn_contact").click(function() {
                        return checkContactFormOnClick (el);
                    });
                });

                load_block = false;
            }
        });
    }
});

$( function () {
    // RG IMG Swiper
    if ($('#fourthSectionRg').length) {
        var swiperRg = new Swiper('.swiper_rg', {
            slidesPerView: 1,
            mode: 'horizontal',
            spaceBetween: 10,
        });
    }

    $('.NETREVIEWS_PRODUCT_STARS').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();

        if (!$('#avis_verifies').hasClass('opened')) {
            onAccordionClick.call(document.getElementById('toggleFpAvis'));
        }

        setTimeout(function () {

            $('html, body').animate({scrollTop: $('#w-netreviews-comments').offset().top - 150});
        }, 600);
    });

    // Search bar
    $(window).scroll(function() {
        // Check if the scroll top is greater than 10 pixels
        if ($(this).scrollTop() > 30) {
            // If true, add the 'scrolled' class to the body (or any other element)
            $('body').addClass('scrolled');
        } else {
            // If false, remove the 'scrolled' class
            $('body').removeClass('scrolled');
        }
    });
});

function searchBtn() {
    let menulv2 = $("#m_animator ul.list_menu.top + ul.list_menu.top");
    let menulv3 = $("#m_animator ul.list_menu.top + ul.list_menu.top + ul.list_menu.top");
    menulv2.css('transition','padding-top 0.2s ease-in-out');
    menulv3.css('transition','padding-top 0.2s ease-in-out');

    // Click search button to show input
    if(!$(".algoliaSearchBar").hasClass("active")) {
        $(".algoliaSearchBar").addClass("active");
        $(".algoliaSearchBar").removeClass("hide");
        $('#left_menu #m_animator').addClass('move_menu_down');

        if ($(window).scrollTop() === 0) {
            $('body').removeClass('searchonscrolled');
        } else if ($('body').hasClass('searchonscrolled')) {
            $('body').removeClass('searchonscrolled');
        } else {
            $('body').addClass('searchonscrolled');
        }
    } else {
        $(".algoliaSearchBar").removeClass("active");
        $(".algoliaSearchBar").addClass("hide");
        $('#left_menu #m_animator').removeClass('move_menu_down');
        $('body').removeClass('searchonscrolled');
    }
}

/* Check menu left if lv1 */
function checkLv1Open() {
    let checkLv1Open = $("#m_animator .list_menu.top:first-child").attr('style');

    if (
        checkLv1Open === 'height:auto;overflow:hidden' ||
        checkLv1Open === 'height: auto; overflow: hidden;') {
            $("body").addClass('menu_lv1_open');
    } else {
        $("body").removeClass('menu_lv1_open');
    }
}

/* on change le nom du btn retour par la ss-cat courante */
function retourToSscat() {
    var verifIndex = 0
    setTimeout(function() {
        document.querySelector("#main_menu_btn").addEventListener("click", function() {
            if (verifIndex === 0) {
                verifIndex++;
                var clickSousCat = 0;
                var allLi = document.querySelectorAll("#left_menu #m_animator ul.list_menu li ul.list_menu .bloc_lnk.vector_sprite.next");
                var tabRetour = [document.querySelector('#sscateg_retour'), document.querySelector('#objet_retour')];

                for (var elem of tabRetour) {
                    elem.addEventListener("click", function(e) {
                        clickSousCat--;
                    });
                }

                for (var elem of allLi) {
                    elem.addEventListener("click", function(e) {
                        clickSousCat++;
                        if (e.target.innerText != Translator.translate('see_all')) {
                            if (clickSousCat <= 1) {
                                document.querySelector("#sscateg_retour > a > span").innerText = e.target.innerText;
                            } else if (clickSousCat <= 2) {
                                document.querySelector("#objet_retour > a > span").innerText = e.target.innerText;
                            }
                        }
                    });
                }
            }
        });
    }, 500);
}

// Menu go to function
function menu_goto(evt) {
    var type, item, next, dpth, hidden;
    evt.preventDefault();

    type = $(this).data('nav-type');
    item = $(this).data('nav-item');
    dpth = menu_goto_type.indexOf( type );

    let menulv2 = $("#m_animator ul.list_menu.top + ul.list_menu.top");
    let menulv3 = $("#m_animator ul.list_menu.top + ul.list_menu.top + ul.list_menu.top");

    if (item === 'back') {

        menulv2.css('transition','');
        menulv3.css('transition','');

        dpth -= 1;
        next = $(this).closest('.list_menu.top').prev();
        $(this).closest('.list_menu.top').prev().scrollTop(0);

        /* Use to display the search bar only on the first level */
        if($(this).parent().attr("id") === "sscateg_retour") {
            $("#left_menu .btn_search").removeClass("show");
        }

        setTimeout(() => {
            var getStyle = $("#left_menu #m_animator > ul.list_menu.top").attr('style');
            if (getStyle === 'height:auto;overflow:hidden' || getStyle === 'height: auto; overflow: hidden;') {
                $('.algoliaSearchBar').removeClass('hide');
            } else {
                $('.algoliaSearchBar').addClass('hide');
            }

            checkLv1Open();
        }, 50);

    } else {
        menulv2.css('transition','');
        menulv3.css('transition','');

        dpth += 1;
        next = $(this).closest('.list_menu.top').next();
        next.children('li').not('.default, .' + item).hide();
        next.children('li.' + item).show();
        $("#left_menu .btn_search").addClass("show");
        $('.algoliaSearchBar').removeClass('active');
        $('.algoliaSearchBar').addClass('hide');

        if( $('li.list_objet_parent').length > 0 ) {
            $('li.' + item + ' ul li.list_objet_parent').hide();
            $('li.' + item + ' ul ' + 'li.' + $(this).closest('li').attr('id')).show();
        }

        setTimeout(() => {
            var getStyle = $("#left_menu #m_animator > ul.list_menu.top").attr('style');
            if (getStyle === 'height:auto;overflow:hidden' || getStyle === 'height: auto; overflow: hidden;') {
                $('.algoliaSearchBar').removeClass('hide');
                $('.algoliaSearchBar').addClass('active');
            } else {
                $('.algoliaSearchBar').addClass('hide');
                $('.algoliaSearchBar').removeClass('active');
                $('#m_animator').removeClass('move_menu_down');
            }
            checkLv1Open();
        }, 50);
    }

    window.setTimeout(hideElm.bind(this), 0);
    next.css('height', 'auto');

    if ((this != undefined) && (this.offsetWidth != undefined)) {
        dpth *= this.offsetWidth;
    }

    $('#m_animator').css({ transform : 'translate(-' + dpth + 'px)' });
}

// Init swiper categorie page adv
setTimeout(function () {
    $('body.cms_page_new .swiperCategories').each(function () {
        if ($(this).find('.swiper-slide').length > 2) {
            swiperCategories = new Swiper('.swiperCategories', {
                preloadImages: false,
                slidesPerView: 2.208,
                slidesPerGroup : 3,
                spaceBetween: 10,
                loop: false,
                nextButton: '.swiperCategories .swiper-button-next',
                prevButton: '.swiperCategories .swiper-button-prev',
                paginationType: 'progress',
                pagination: '.swiperCategories .swiper-pagination'
            });
        } else {
            $(this).addClass("no-arrow");
        }
    });
},250);

if ($("#orders_by_type").length) {
    $("body").on("DOMSubtreeModified", function() {
        $(".prelast.refund + .refund").parent().addClass("visible");
    });
}

function ajaxLoadCp(type, id) {
    $.ajax({
        type: 'post',
        url: path_relative_root + create_link('ajax_get_list_cms_page'),
        data: 'type=' + type + '&id=' + id,
        success: function(result) {
            result = JSON.parse(result);

            // generate cms page preview
            if (result.success) {
                $('#cms_subcategory_menu .item.selected').removeClass('selected');

                if (type == "cms_category") {
                    $('#cms_subcategory_menu .see_all').hide();
                } else {
                    $('#cms_subcategory_menu .see_all').show();
                    $('#csc_menu_' + id).addClass('selected');
                }

                if (!$('#cms_subcategory_menu .item').hasClass('selected')) {
                    $('#cms_subcategory_menu .see_all').addClass('selected');
                } else {
                    $('#cms_subcategory_menu .see_all').removeClass('selected');
                }

                $('#content_previsu_cms_page').remove();
                $('#cms').append(result.html);
            }
        }
    });
}

// Toggles side menu of given id comming from given side
function toggleMenu(id) {
    var menu = document.getElementById(id + '_menu');
    var shad = document.getElementById('shade');
    var iconMenu = document.getElementById('main_menu_btn');
    var myInput = $(".algoliaSearchBar input.aa-Input");
    var emptyInputRecherche = $(".algoliaSearchBar .aa-InputWrapperSuffix .aa-ClearButton");

    if (menu) {
        if (menu.classList.toggle('open') && shad) {
            $(".algoliaSearchBar").animate({
                opacity: 0
            }, 100);

            shad.classList.add('visible');
            $("body").addClass("noscroll");
            $("body").addClass("menuLeftOpen");
            $("body.menuLeftOpen .algoliaSearchBar").removeClass("changeBgColor");
            $("body.menuLeftOpen .algoliaSearchBar").removeClass("hide");

            setTimeout(() => {
                $(".algoliaSearchBar").animate({
                    opacity: 1
                }, 100);

                $(".algoliaSearchBar").addClass("toMenuLeft");

                if(!$("body").hasClass("menu_lv1_open")) {
                    $(".algoliaSearchBar.toMenuLeft").addClass("hide");
                }

                $("body.menuLeftOpen .algoliaSearchBar .aa-Input").on( "focus", function () {
                    $(this).addClass('movePlaceholder');
                });

                $("body.menuLeftOpen .algoliaSearchBar .aa-Input").on("blur", function() {
                    $(this).removeClass('movePlaceholder');
                });
            }, 500);

            shad.classList.add('forMenu');
            checkLv1Open();

            if (id == "left") {
                iconMenu.classList.add("menu_open");
                myInput.on("focus", function() {
                    setTimeout(() => {
                        if($(".algoliaSearchBar form.aa-Form .aa-InputWrapperSuffix").length < 2) {
                            $(".algoliaSearchBar form.aa-Form .aa-InputWrapperSuffix").clone().prependTo(".algoliaSearchBar form.aa-Form");
                        }
                    }, 200);
                });
            } else {
                shad.classList.add('forFilters');
            }
            
            
            if (id == "filters") {
                $(".filters").overlayScrollbars({});
            }

        } else if (shad) {
            shad.classList.remove('visible');
            shad.classList.remove('forMenu');
            shad.classList.remove('forFilters');
            $("body").removeClass("noscroll");
            setTimeout(() => {
                $("body").removeClass("menuLeftOpen");
            }, 500);
            $(".algoliaSearchBar").removeClass("toMenuLeft");
            // $(".fake_algoliaSearchBar").removeClass("hide");

            if (id == "left") {
                iconMenu.classList.remove("menu_open");
            }
        }

    } else if (shad) {
        shad.classList.toggle('visible');
        checkLv1Open();
    }
}

function initProductVisited() {
    if ($('#productVisitedSwiper .swiper-slide').length > 1) {

        if ($('#productVisitedSwiper .swiper-slide').length <= 2) {
            $('#gondole_derniers_articles_vus .wrap_navigation').addClass('hide');
        }

        try {
            var gamm_slider = new Swiper('#productVisitedSwiper', {
                slidesPerView: 2,
                paginationClickable: true,
                loop: false,
                preloadImages: true,
                lazyLoading: true,
                spaceBetween: 12,
                nextButton: '#gondole_derniers_articles_vus .visited-next.swiper-button-next',
                prevButton: '#gondole_derniers_articles_vus .visited-prev.swiper-button-prev',
                scrollbar: '#gondole_derniers_articles_vus .swiper-scrollbar',
                scrollbarDraggable: true,
                scrollbarHide: false,
            });
        } catch (e) {
            console.warn('Error initializing productVisitedSwiper:', e);
        }
    } else {
        // Instant hide navigation if there are not enough slides
        $('#gondole_derniers_articles_vus .wrap_navigation').addClass('hide');
    }
}

// Intializes all sliders on product page
function initFpSliders() {

    // Init sizes and colors swipers for main product
    $('#content_product .choices_list').each(function () {
        let zis = $(this);
        let swiper_spaceBetween;
        // different spaceBetween if color or size
        if (zis.hasClass('swiperSizes')) {
            swiper_spaceBetween = 10;
        } else {
            swiper_spaceBetween = 25;
        }

        let parent = $(this).parents('.product_fields');
        new Swiper(zis[0], {
            slidesPerView: 'auto',
            roundLengths: true,
            setWrapperSize: true,
            spaceBetween: swiper_spaceBetween,
            prevButton: parent.find('.choices_list_prev')[0],
            nextButton: parent.find('.choices_list_next')[0],
            onInit: function () {
                parent.find('.choices_list_prev').removeClass('cache');
                parent.find('.choices_list_next').removeClass('cache');
            },
        });
    })

    if ($('.product_gammes_wrapper').length) {
        // foreach .product_gammes_wrapper, check the number of slides and hide navigation if needed, then init swiper

        $('.product_gammes_wrapper').each(function () {
            if ($(this).find('.swiper-slide').length < 3) {
                $(this).find('.wrap_navigation').addClass('hide');
            }

            var swiper = new Swiper($(this).find('.swiper-container'), {
                slidesPerView: 2,
                paginationClickable: true,
                spaceBetween: 12,
                preloadImages: true,
                lazyLoading: true,
                loop: false,
                nextButton: $(this).find('.swiper-button-next'),
                prevButton: $(this).find('.swiper-button-prev'),
                scrollbar: $(this).find('.swiper-scrollbar'),
                scrollbarDraggable: true,
                scrollbarHide: false,
            });
        });
    }

    if ($('.product_associations_wrapper #associations_by_rayon').length) {
        if ($('.product_associations_wrapper.ranges .swiper-slide').length < 3) {
            $('.product_associations_wrapper.ranges .wrap_navigation').addClass('hide');
        }

        var swiper = new Swiper('.product_associations_wrapper #associations_by_rayon', {
            slidesPerView: 2,
            paginationClickable: true,
            spaceBetween: 12,
            preloadImages: true,
            lazyLoading: true,
            loop: false,
            nextButton: '.product_associations_wrapper.ranges .swiper-button-next',
            prevButton: '.product_associations_wrapper.ranges .swiper-button-prev',
            scrollbar: '.product_associations_wrapper.ranges .swiper-scrollbar',
            scrollbarDraggable: true,
            scrollbarHide: false,
        });
    }

    if ($('.product_associations_wrapper #associations_by_product').length) {
        if ($('.product_associations_wrapper.product .swiper-slide').length < 3) {
            $('.product_associations_wrapper.product .wrap_navigation').addClass('hide');
        }

        var swiper = new Swiper('#associations_by_product', {
            slidesPerView: 2,
            paginationClickable: true,
            spaceBetween: 12,
            preloadImages: true,
            lazyLoading: true,
            loop: false,
            nextButton: '.product_associations_wrapper.product .swiper-button-next',
            prevButton: '.product_associations_wrapper.product .swiper-button-prev',
            scrollbar: '.product_associations_wrapper.product .swiper-scrollbar',
            scrollbarDraggable: true,
            scrollbarHide: false,
        });
    }

    // Product visited swiper
    initProductVisited();
}

if ($('.bandeauMenuAffix').length) {
    var isTop = true;

    $(document).scroll(function () {
        if ($(window).scrollTop() === 0 && !isTop) {
            isTop = true;
            $(".bandeauMenuAffix").fadeTo("fast", 1).css('pointer-events', 'auto');
        } else if ($(window).scrollTop() > 1 && isTop) {
            isTop = false;
            $(".bandeauMenuAffix").fadeTo("fast", 0).css('pointer-events', 'none');
        }
    });
}

//Function to create a store div infos personalised for woosmap
function createLineWoosmap(store, MapsProviderService ){
    let div = '';
        let storeDetailsUrl = 'store_details.php?id=' + store.properties.store_id;
        let zipcodeAndCity = '';
        if (zipcode = store.properties.address.zipcode) {
            zipcodeAndCity = zipcode;
        }
        if (city = store.properties.address.city) {
            zipcodeAndCity += ' ' + this.formatAddress(city);
        }
        zipcodeAndCity = zipcodeAndCity.trim();
        if (MapsProviderService.device != null && MapsProviderService.device == 'mobile') {
            div = `<div class="bloc_marker_info">
                <p style="font-size:12px">
                    <span class="marker_store_name">${store.properties.name}</span>
                    <p style="font-weight:bold;margin-top:10px;">Adresse</p>
                    <span class="marker_store_adr">${store.properties.address.lines.join('<br>')}</span>
                    <span class="marker_store_cp">${zipcodeAndCity}</span>
                </p>
                <div class="cta_marker_info">
                    <span class="learn_more"><a href="${storeDetailsUrl}">${Translator.translate('learn_more')}</a></span>
                    <a class="marker_go_map" href="">${Translator.translate('go_to_store')}</a>
            </div>`;
        } else {
            div = '<div class="info_popup">';

            div += '<div class="name"><a href="' + storeDetailsUrl + '">' + store.properties.name + '</a></div>';

            div += '<div class="adresse"><p style="font-weight:bold;margin-top:10px;">' + Translator.translate('adresse') + '</p>';
            div += formatAddress(store.properties.address.lines.join('<br>'));

            if (zipcodeAndCity.length > 0) {
                div += '<br>' + zipcodeAndCity
            }
            if (country = store.properties.address.countryCode) {
                div += '<br>' + country;
            }
            div += '</div>';
            if(store.properties.contact.phone){
                div += '<div class="store_infos">' + formatPhone(store.properties.contact.phone) + '</div>';
            }

            div += '<div class="horaire_info"><p class="horaire_title" style="font-weight:bold;margin-top:10px;">' + Translator.translate('horaires_map') + '</p>';
            let openingTimes = [];
            for (weekday = 1; weekday <= 7; weekday++) {
                if (store.properties.weekly_opening) {
                    let hours = store.properties.weekly_opening[weekday].hours;
                    let dayOpeningTimes = [];
                    if (store.properties.weekly_opening[weekday].hours.length > 0) {
                        for (let i in hours) {
                            if (hours[i].start.length > 0 && hours[i].end.length > 0) {
                                dayOpeningTimes.push(hours[i].start + '-' + hours[i].end);
                            }
                        }
                    } else {
                        dayOpeningTimes.push(Translator.translate('delivery_store_closed'));
                    }
                    dayOpeningTimes = Translator.translate('day_0' + weekday) + ' : ' + dayOpeningTimes.join(', ')
                    if (store.properties.open.open_now && store.properties.open.weekday == weekday) {
                        dayOpeningTimes = '<span class="currently_open">' + dayOpeningTimes + '</span>';
                    }
                    openingTimes.push(dayOpeningTimes);
                }
            }
            div += openingTimes.join('<br>');
            div += '</div>';
            if ( store.properties.magImgDisplay){
            div += '<div class="store_infos_img">' + store.properties.magImgDisplay + '</div>';
            }
            div += '<br><a class="savoir_plus" href="' + storeDetailsUrl + '">' + Translator.translate('popup_shop_info') + '</a>';

            div += '</div>';
        }

        return div;
}

function formatAddress (address) {
    return address.charAt(0).toUpperCase() + address.slice(1).toLowerCase();
}

function formatPhone (phone) {
    return phone.replace("+33", "0").replace(/(.{2})(.{2})(.{2})(.{2})(.{2})/, "$1 $2 $3 $4 $5");
}

// popup PDV
function showpdv() {
    var shade, modbox;
    $.ajax({
        type: "post",
        url: path_relative_root + "ajax-distributeurs.php",
        success: function (res) {
            if (res) {
                shade = document.querySelector("#shade.modal_shade");
                modbox = document.getElementById("geofiltre");

                // Inject store locator into modbox
                $(".store_locator").html(res);

                if (typeof MapsProviderService === 'undefined' || MapsProviderService.provider.name == 'googlemaps') {
                    initialize();
                }

                // Show modbox and make sure closing routine won't fire more than once

                $(shade).off('click', closepdv)
                $(shade).addClass('actif');
                $(shade).on("click", closepdv);
                $("#pdv_load").removeClass("loading");

                $(modbox).addClass('bottom_lightbox');
                var height = $(modbox).outerHeight();
                if ($(modbox).children('.bottom_lightbox_close').length == 0) {
                    $(modbox).append('<div class="bottom_lightbox_close" onclick="closepdv()"></div>');
                }
                $(modbox).css({
                    opacity: 1,
                    display: 'block',
                    zIndex: 10000,
                    bottom: -height,
                    top: 'unset',
                    left: 0,
                    right: 0
                })
                .animate({
                    bottom: 0
                }, 300);
            }
        },
    });
}

document.addEventListener('DOMContentLoaded', function() {
    var checkboxes = document.querySelectorAll('.filtre-checkbox');
    checkboxes.forEach(function(checkbox) {
        checkbox.addEventListener('click', masquerElements);
    });
});
// function to add specifique type of filter on the store locatare page
function masquerElements() {
    var visibleElements = 0; // Initialisation of the number of visible elements
    var elementCountSpan = document.querySelector('.element_count');
    var elementCountDefault = document.querySelector('.resultat_default');

    var checkedCheckboxes = document.querySelectorAll('.filtre-checkbox:checked');

    if (checkedCheckboxes.length > 1) {
        checkedCheckboxes.forEach(function(checkbox) {
            if (checkbox !== this) {
                checkbox.checked = false; // uncheck all checkbox
            }
        }, this);
        checkedCheckboxes = document.querySelectorAll('.filtre-checkbox:checked');
    }

    var elementsToHide = document.querySelectorAll('.store_infos_ambassadeur, .store_infos_referent, .store_infos_essentiel');
    elementsToHide.forEach(function(element) {
        element.closest('.elem_liste_distributeur').style.display = 'none'; // hide all element first
    });

    if (checkedCheckboxes.length === 0) {
        // if nothing check display all elements
        var allElements = document.querySelectorAll('.elem_liste_distributeur');
        allElements.forEach(function(element) {
            element.style.display = '';
        });
    } else {
        // display specific elements
        checkedCheckboxes.forEach(function(checkbox) {
            var elementsToShow;
            if (checkbox.id === 'ambassadeur') {
                elementsToShow = document.querySelectorAll('.store_infos_ambassadeur');
            } else if (checkbox.id === 'referent') {
                elementsToShow = document.querySelectorAll('.store_infos_referent');
            } else if (checkbox.id === 'essentiel') {
                elementsToShow = document.querySelectorAll('.store_infos_essentiel');
            }

            elementsToShow.forEach(function(element) {
                element.closest('.elem_liste_distributeur').style.display = 'block'; // Afficher les éléments correspondants
            });
        });
    }

    // count number of element
    visibleElements = document.querySelectorAll('.elem_liste_distributeur:not([style*="display: none"])').length;

    if (elementCountSpan !== null) {
        elementCountDefault.style.display = 'none';
        if (visibleElements === 0) {
            // case no visible element
            elementCountSpan.textContent = "aucun magasin trouvé";
        } else if (visibleElements === 1) {
            // Case only one element visible
            elementCountSpan.textContent = visibleElements + " magasin trouvé";
        } else {
            // Case multiple element visible
            elementCountSpan.textContent = visibleElements + " magasins trouvés";
        }
    }
}

// This function bellow allows closing Woosmap when clicking/touch outside and inside the suggestions container.
function clickOutsideWoosmapHandler(bodyClass, parentSelector) {
    if ($('body').hasClass(bodyClass) && $('body').hasClass('.fr')) {
        $(document).on('click touchstart', function(event) {
            var $autocompleteContainer = $('#search_cp-completion');
            var $autocompleteContainerProduct = $('#found_store-completion');
            if ($autocompleteContainer.length) {
                if (!$.contains($autocompleteContainer[0], event.target)) {
                    var $parentInput = $autocompleteContainer.closest(parentSelector).find('input');
                    if ($(document.activeElement)[0] !== $parentInput[0]) {
                        closeAutocomplete($autocompleteContainer);
                    }
                } else {
                    setTimeout(function() {
                        closeAutocomplete($autocompleteContainer);
                    }, 500);
                }
            }

            if ($autocompleteContainerProduct.length) {
                if (!$.contains($autocompleteContainerProduct[0], event.target)) {
                    // Gestion du clic en dehors du conteneur
                    var $parentInput = $autocompleteContainerProduct.closest(parentSelector).find('input');
                    if ($(document.activeElement)[0] !== $parentInput[0]) {
                        closeAutocomplete($autocompleteContainerProduct);
                    }
                }
            }
        });

        function closeAutocomplete($autocompleteContainer) {
            $autocompleteContainer.hide();
        }
    }
}

// Using the function for different contexts

clickOutsideWoosmapHandler('tunnel', '.fact_choice');
clickOutsideWoosmapHandler('store_locator', '.field_address');
clickOutsideWoosmapHandler('customer', '.form_item');
clickOutsideWoosmapHandler('product_page', '.search_store_wrap');

// mobile modale contact_mag
function handleChangeMobile(id, nom) {
    var formStore = document.querySelector(".formStore");
    var inputMagId = formStore.querySelector("#id_magasin_form");
    var shad = document.querySelector("#shade");

    inputMagId.value = id;
    $(formStore).parent().show();

    if ($("body.product_page").length) {
        closeMultiShad("geofiltre");
        $(".formStore .valide").hide();
        $('.formStore').removeClass('thanks');
        $(".formStore .block-content").show();
        setTimeout(function() {
            openMultiShad("contact_store_lightbox", "bottom");
        }, 500);
    } else {
        $(formStore).find('#message').find('#nom_magasin').html(nom);
        formStore.classList.remove("cache");
        formStore.classList.remove("thanks");
        openMultiShad("shade");
        shad.classList.add("contact_mag");
        shad.addEventListener("click", closeModalContactMagMobile);
    }

    if ($("#clientTel").length) {
        $('#clientTel').intlTelInput();
    }
}

function closeModalContactMagMobile(){
    var formStore = document.querySelector(".formStore");
    var shad = document.querySelector("#shade");

    formStore.classList.add("cache");
    shad.classList.add("contact_mag");
    $(formStore).parent().hide();
    closeMultiShad("shade");
    shad.removeEventListener("click", closeModalContactMagMobile);
}

/* NETREVIEWS
    First switch, ID is in DOM, replace by ID
    After the first switch replace by tag name
*/
function updateReviews(avisVerifiesReviews, product_refext) {

    if (typeof avisVerifiesReviews !== 'undefined') {
        if ($("w-netreviews-comments").length > 0) {
            $("w-netreviews-comments", '#contents_wrapper').replaceWith('<div id="w-netreviews-comments">' + avisVerifiesReviews.html() + '</div>');
        } else {
            $("#w-netreviews-comments", '#contents_wrapper').replaceWith('<div id="w-netreviews-comments">' + avisVerifiesReviews.html() + '</div>');
        }
    }

    // Reload vuejs netreviews
    if (typeof wShop !== 'undefined') {
        // Netreviews reviews
        if (typeof wShop.$refs.wNetreviewsComments !== 'undefined') {
            wShop.$refs.wNetreviewsComments.reload(product_refext);
        }
    }

    // STATIC NETREVIEWS SWIPER
    var swiperNetreviews = new Swiper('.swiperNetreviews', {
        'slidesPerView': '1.15',
        'slidesOffsetBefore': 32,
        'spaceBetween': 10,
        'nextButton': '#nrc_comments .nextSlideFp',
        'prevButton': '#nrc_comments .prevSlideFp',
        'scrollbar': '#nrc_comments .swiper-scrollbar',
        'scrollbarDraggable':  true,
        'scrollbarHide': false,
    });
    // add it to the global scope
    window.swiperNetreviews = swiperNetreviews;

    $('.NETREVIEWS_PRODUCT_STARS').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();

        if (!$('#avis_verifies').hasClass('opened')) {
            onAccordionClick.call(document.getElementById('toggleFpAvis'));
        }

        setTimeout(function () {

            $('html, body').animate({scrollTop: $('#w-netreviews-comments').offset().top - 150});
        }, 600);
    });
}

document.addEventListener('DOMContentLoaded', function() {
    // VERIFIED REVIEWS START
    // grab all .nrc_date elements and format them
    var nrc_date = document.querySelectorAll('.nrc_date');
    for (var i = 0; i < nrc_date.length; i++) {
        // fix dates for safari because they don't like YYYY-MM-DD, they prefer YYYY/MM/DD
        var date = new Date(nrc_date[i].textContent.replace(/-/g, '/'));
        var lang_iso = typeof lang_iso != "undefined" ? lang_iso : "fr";
        var strMonth = date.toLocaleString(lang_iso, { month: 'short' });

        nrc_date[i].textContent = date.getDate() + ' ' + strMonth + ' ' + date.getFullYear();
    }
    // STATIC NETREVIEWS SWIPER
    var swiperNetreviews = new Swiper('.swiperNetreviews', {
        'slidesPerView': '1.15',
        'slidesOffsetBefore': 32,
        'spaceBetween': 10,
        'nextButton': '#nrc_comments .nextSlideFp',
        'prevButton': '#nrc_comments .prevSlideFp',
        'scrollbar': '#nrc_comments .swiper-scrollbar',
        'scrollbarDraggable':  true,
        'scrollbarHide': false,
    });
    // add it to the global scope
    window.swiperNetreviews = swiperNetreviews;
    // check the slides if there's a "see more" span, if so hide the full length text, init the modal to show more on click

    // when clicking progress bar with number of reviews for a category:
    //  move all reviews that arent in that category to #swiper-storage, order them by data-id and reinit swiper (category is set via the data-rate attribute)
    // .total_review displays all
    // .nrc_chart_single displays only their category (if they have a data-count higher than 0)
    $('.total_reviews').on('click', function () {

        // disable the panel to prevent multiple/parallel clicks
        $("#w-netreviews-comments").css('pointer-events', 'none')

        // move all reviews from the storage div to the main swiper if they exist
        // order them by data-id and reinit swiper
        $('#w-netreviews-comments #swiper-storage .swiper-slide').each(function () {
            $('#w-netreviews-comments .swiper-wrapper').append(this);
        });

        $('#w-netreviews-comments .swiper-wrapper .swiper-slide').sort(function (a, b) {
            return $(a).data('id') - $(b).data('id');
        }).appendTo('#w-netreviews-comments .swiper-wrapper');

        swiperNetreviews.update();
        swiperNetreviews.slideTo(0);

        // enable the panel
        $("#w-netreviews-comments").css('pointer-events', 'auto')
    });

    $('.nrc_chart_single').on('click', function () {
        if ($(this).data('count') == 0) {
            return;
        }

        // turn the opacity of the container down to show that the reviews are being moved
        // & disable the panel to prevent multiple/parallel clicks
        $("#w-netreviews-comments").css('pointer-events', 'none')
        $('#w-netreviews-comments .swiper-container').css('opacity', '0.3');

        // move all reviews to the storage div
        // add reviews with the same category to the main swiper
        // order them by data-id and reinit swiper

        $('#w-netreviews-comments .swiper-wrapper .swiper-slide').each(function () {
            // append, if given a jQuery object, will move the element
            $('#swiper-storage').append($(this));
        });

        let category = $(this).data('rate');
        $('#w-netreviews-comments #swiper-storage .swiper-slide').each(function () {
            if ($(this).data('rate') == category) {
                // append, if given a jQuery object, will move the element
                $('#w-netreviews-comments .swiper-wrapper').append($(this));
            }
        });

        $('#w-netreviews-comments .swiper-wrapper .swiper-slide').sort(function (a, b) {
            return $(a).data('id') - $(b).data('id');
        }).appendTo('#w-netreviews-comments .swiper-wrapper');

        swiperNetreviews.update();
        swiperNetreviews.slideTo(0);

        // turn the opacity back to 1 & enable the panel
        $('#w-netreviews-comments .swiper-container').css('opacity', '1');
        $("#w-netreviews-comments").css('pointer-events', 'auto')
    });

    // lightbox : on a .nrc_comment_see_more click, grab all the contents of the review and put it in #nrc_comment
    // then display it with a fade in, bind the close button to fade out, remove content...
    $('.nrc_comment_see_more').on('click', function () {
        var content = $(this).parents('.swiper-slide');

        var name_date = content.find('.name_date').html();
        var rate = content.find('.nrc_rate').html();
        var comment = content.find('.nrc_comment').html();
        var disclaimer = content.find('.nrc_comment_disclaimer').html();
        var order_date = content.find('.nrc_comment_order_date').html();

        $('#nrc_comment .name_date').html(name_date);
        $('#nrc_comment .nrc_rate').html(rate);
        $('#nrc_comment .nrc_comment').html(comment);
        $('#nrc_comment .nrc_comment_disclaimer').html(disclaimer);
        $('#nrc_comment .nrc_comment_order_date').html(order_date);

        // find the text content (.nrc_comment) and remove the see more span and the truncated content to only keep the full text (.nrc_comment_full)
        $('#nrc_comment').find('.nrc_comment_see_more').remove();
        $('#nrc_comment').find('.nrc_comment_truncated').remove();

        $('#shade').addClass('visible');
        $('#nrc_comment').fadeIn(200);

        $('#nrc_comment .close, #shade').on('click', function () {
            $('#nrc_comment').fadeOut(200);
            $('#shade').removeClass('visible');
            $('#nrc_comment .nrc_comment_content').html('');
            $('#nrc_comment .close, #shade').off('click');
        });

    });
});

// on the login form, mirror the "no" radio button to the separated 'checkbox' at the bottom
// observe the radio button and update the checkbox accordingly, and vice versa
function mirrorOptoutCheckbox() {
    var fakeOptoutReplacement = '.nbDenyOptinSimple';
    var fakeOptoutCheckbox = '#deny-optin input[type="radio"]';
    var fakeOptoutCheckboxContainer = '#deny-optin';
    var trueOptoutCheckboxSignup = '.optin_container input[type="radio"][value="0"]';
    var trueOptoutCheckboxLogin ='.optin_container input[type="radio"][value="0"]';
    var trueOptinCheckboxSignup = '.optin_container input[type="radio"][value="1"]';
    var trueOptinCheckboxLogin ='.optin_container input[type="radio"][value="1"]';

    // bind the event to the body, so it works even if the form is loaded after the script
    // if any radio button is changed, check if it corresponds to any of the 'checkboxes'
    document.addEventListener('change', function(e) {
        if (e.target.matches(fakeOptoutCheckbox)) {
            try {
                document.querySelector(trueOptoutCheckboxSignup).checked = e.target.checked;
            } catch (error) {
                console.warn('Error occurred while handling trueOptoutCheckboxSignup:', error);
            }

            try {
                document.querySelector(trueOptoutCheckboxLogin).checked = e.target.checked;
            } catch (error) {
                console.warn('Error occurred while handling trueOptoutCheckboxLogin:', error);
            }
        }

        if (e.target.matches(trueOptinCheckboxSignup) || e.target.matches(trueOptinCheckboxLogin)) {
            document.querySelector(fakeOptoutCheckbox).checked = !e.target.checked;
        }
    });

    // we need to add an observer to the body, if at any point the radio button is changed, we need to make sure the user isn't stuck

    var observer = new MutationObserver(function(mutations) {
        let fakeCheckboxStatus = "neutral"; // neutral, toHide, toShow

        mutations.forEach(function(mutation) {
            // if at any point one of the true elements is removed from the dom, hide the fake checkbox
            if (mutation.removedNodes) {
                if (!document.querySelector(trueOptinCheckboxSignup) && !document.querySelector(trueOptinCheckboxLogin)) {
                    // if the true optin checkbox is removed, fade out the fake checkbox
                    fakeCheckboxStatus = "toHide";
                }
            }

            // if at any point one of the true elements is added to the dom, check its state and update the fake checkbox accordingly
            if (mutation.addedNodes) {

                if (document.querySelector(trueOptinCheckboxSignup) || document.querySelector(trueOptinCheckboxLogin)) {
                    // if the true optin checkbox is added, fade in the fake checkbox
                    fakeCheckboxStatus = "toShow";
                }

                // if the true optout checkbox is added, check its state and update the fake checkbox accordingly
                try {
                    if (document.querySelector(trueOptoutCheckboxSignup)) {
                        if (document.querySelector(trueOptoutCheckboxSignup).checked) {
                            // if the true optout checkbox is checked, check the fake checkbox
                            document.querySelector(fakeOptoutCheckbox).checked = true;
                        } else {
                            // if the true optout checkbox is unchecked, uncheck the fake checkbox
                            document.querySelector(fakeOptoutCheckbox).checked = false;
                        }
                    }
                } catch (error) {
                    console.warn('Error occurred while handling trueOptoutCheckboxSignup:', error);
                }

                try {
                    if (document.querySelector(trueOptoutCheckboxLogin)) {
                        if (document.querySelector(trueOptoutCheckboxLogin).checked) {
                            // if the true optout checkbox is checked, check the fake checkbox
                            document.querySelector(fakeOptoutCheckbox).checked = true;
                        } else {
                            // if the true optout checkbox is unchecked, uncheck the fake checkbox
                            document.querySelector(fakeOptoutCheckbox).checked = false;
                        }
                    }
                } catch (error) {
                    console.warn('Error occurred while handling trueOptoutCheckboxLogin:', error);
                }
            }
        });

        // update the fake checkbox status depending on the state of the fakeCheckboxStatus variable
        if (fakeCheckboxStatus === "toHide") {
            $(fakeOptoutReplacement).show();
            $(fakeOptoutCheckboxContainer).fadeOut();
        } else if (fakeCheckboxStatus === "toShow") {
            $(fakeOptoutReplacement).hide();
            $(fakeOptoutCheckboxContainer).fadeIn();
        }
    });

    observer.observe(document.body, {
        childList: true,
        subtree: true
    });
}

document.addEventListener('DOMContentLoaded', function() {
    mirrorOptoutCheckbox();
});

function showLightBoxSelectionOfferte() {
	$.ajax({
		type: 'post',
		url: path_relative_root+'ajax_lightbox_selection_offerte.php',
		success: function( response ){
			if($('#selectionOffertePopup').length == 0){
				$(".encart_selection_offert").html(response);
			}

            var id_produit_offert_unique = $('.ligne_produit_offert').length == 1 ? $('.ligne_produit_offert').data('idproduit'):0;

			// Si le nombre de selection égale 1 on ajoute le produit directement dans le panier, sinon on affiche la lightBox avec la selection
			if (nb_selection_offert == 1 &&
                $('.ligne_produit_offert').length == 1 &&
                $("#qteProd_" + id_produit_offert_unique).val()>0 &&
                (
                    ((document.getElementById("couleurProd_" + id_produit_offert_unique) && $("#couleurProd_" + id_produit_offert_unique).children('option').length == 1) ||
                    !document.getElementById("couleurProd_" + id_produit_offert_unique))
                ) &&
                (
                    ((document.getElementById("tailleProd_" + id_produit_offert_unique) && $("#tailleProd_" + id_produit_offert_unique).children('option').length == 2) ||
                    !document.getElementById("tailleProd_" + id_produit_offert_unique))
                )
            ) {
				$('.ligne_produit_offert').addClass('seleted');
                ajoutLightBoxSelectionOfferte();
			} else {
				openMultiShad('selectionOfferteContainer', 'bottom');
			}
		}
	});
}

function operateForSelectionOfferte(idProduit) {
    if ($('.ligne_produit_offert.seleted').length > 0) {
        if ($('#couleurProd_' + idProduit + ' option:selected').length > 0) {
            if ($('.ligne_produit_offert.seleted #form_produit_offert_' + idProduit + '')) {
                $('.ligne_produit_offert.seleted .first_bloc_produit_offert #img_medium_' + idProduit + '').attr('src', $('#couleurProd_' + idProduit + ' option:selected').data('src-img'));

                // Update selected option
                $('.ligne_produit_offert.seleted #form_produit_offert_' + idProduit + ' .color_previsu').css('background', 'url(' + $('#couleurProd_' + idProduit + ' option:selected').data('src-img') + ') center center / contain repeat scroll transparent');

                // Also update color line in the lightbox
                $('.ligne_produit_offert.seleted').find('.product_color').html($('.ligne_produit_offert.seleted').find('option:selected').html());
            } else {
                $('#produit_offert_' + idProduit + '.ligne_produit_offert.seleted img').attr('src', $('#couleurProd_' + idProduit + ' option:selected').data('src-img'));

                // Update selected option
                $('#produit_offert_' + idProduit + '.ligne_produit_offert.seleted .color_previsu').css('background', $('#couleurProd_' + idProduit + ' option:selected').data('src-img') + 'center center / contain repeat scroll transparent');

                // Also update color line in the lightbox
                $('#produit_offert_' + idProduit + '.ligne_produit_offert.seleted').find('.product_color').html($('.ligne_produit_offert.seleted').find('option:selected').html());
            }
        }
    }
}

// WP-27674: Add a class to the input fields if they are empty and required
function checkWishlistForm() {

    var form = $('#wishlist_share');
    var inputFields = $('input[type="text"], input[type="email"], textarea', form);
    var arrayRequired = ['#sendWishListYourMail', '#sendWishListToMails']

    inputFields.on('blur', function(){
        if ($(this).val() === '' && arrayRequired.includes('#' + $(this).attr('id'))) {
            $(this).addClass('inputErr');
        } else {
            if ($(this).hasClass('inputErr')) {
                $(this).removeClass('inputErr');
            }
        }
    })
}

// Override APP function
function sendWishListForm(evt) {

    var loadze, form, theme, to_mails, to_message, is_message_required, is_name_required, your_name, your_mail, errbox,
            output_txt;

    evt.preventDefault();

    loader = $(this.nextElementSibling);

    loader.fadeIn(200, function () {

        form = $(this).closest('form').get(0);

        to_mails = form.sendWishListToMails;
        to_message = form.sendWishListToMessage;
        your_name = form.sendWishListYourName;
        your_mail = form.sendWishListYourMail;
        is_message_required = false; // WP-27674
        is_name_required = false; // WP-27674
        copy = form.sendFriendCopy.checked;
        optin = form.optin.checked;

        errbox = document.getElementById('item_error');
        token = document.getElementById('token_security_sendwishlist').value;
        captcha = document.getElementById('g-recaptcha-response').value;

        if (checkRequiredInputs('#wishlist_share')) {
            $.ajax({
                url: path_relative_root + create_link('ajax_send_wishlist_form'),
                type: 'get',
                data: {
                    theme: theme, to_mails: to_mails.value,
                    to_message: to_message.value, your_name: your_name.value,
                    your_mail: your_mail.value,
                    token_security : token,
                    is_message_required : is_message_required,
                    is_name_required : is_name_required,
                    copy : copy,
                    optin : optin,
                    'g-recaptcha-response': captcha
                },
                success: function (res) {

                    $('.inputErr', form).removeClass('inputErr');

                    if (res.indexOf('ok') === 0) {

                        var s_adresse = res.substr(3);
                        var sing = (res.split(',').length > 2) ? 'sendwish_ok_plur_mobile' : 'sendwish_ok_sing_mobile';

                        output_txt = Translator.translate(sing, s_adresse);

                        $(errbox).addClass('success');

                        var wl_height = $('#wishlist_share').outerHeight();
                        $('#wishlist_share').animate({
                            bottom: -wl_height
                        }, 300).css({
                            opacity: 0,
                            zIndex: -1,
                        });
                    } else {

                        if (!isNaN(Number(res))) {

                            switch (Number(res)) {

                                case 1:
                                case 2:
                                    $(to_mails).addClass('inputErr');
                                    output_txt = Translator.translate('sendfriend_error_mail_friends_invalid');
                                    break;

                                case 3:
                                    $(to_message).addClass('inputErr');
                                    output_txt = Translator.translate('sendfriend_error_no_message');
                                    break;

                                case 4:
                                    $(your_name).addClass('inputErr');
                                    output_txt = Translator.translate('sendfriend_error_no_name');
                                    break;

                                case 5:
                                case 6:
                                    $(your_mail).addClass('inputErr');

                                case 5:
                                    output_txt = Translator.translate('sendfriend_error_no_my_mail');
                                    break;

                                case 6:
                                    output_txt = Translator.translate('sendfriend_error_my_mail_invalid');
                                    break;
                            }
                        } else if(res == 'token_error'){
                            output_txt = Translator.translate('error_token_security');
                        } else if (Number(res.split(',')[0]) === 7) {

                            var s_adresse = res.substr(3);
                            var sing = (res.split(',').length > 2) ? 'sendwish_no_ok_plur_mobile' : 'sendwish_no_ok_sing_mobile';

                            output_txt = translate(sing, s_adresse);
                        }
                    }

                    $('.modbox_subtitle', errbox).html('<span>' + output_txt + '</span>');

                    var errbox_height = $(errbox).outerHeight();
                    $(errbox).animate({
                        bottom: 0
                    }, 300);
                    $(errbox).addClass('open')

                    $('#shade').on('click', function (){
                        $(errbox).animate({
                            bottom: -errbox_height
                        }, 300);
                    })

                    loader.stop(true, true).fadeOut(200);

                    $("html,body").css('position','initial');
                }
            });
        }

    });
}

// Initialize all Dailymotion videos present in the DOM using the Dailymotion Web SDK.
// This ensures proper integration, autoplay / pause features.
function initDailymotionVideos() {
    $(".player-container-dailymotion").each(function () {
        try {
            dailymotion.createPlayer($(this).attr('id'), {
                video: $(this).attr('data-video-id'),
                params: {
                    loop: true,
                    mute: true
                }
            }).then(dailymotionPlayer => {
                if (dailymotionPlayer) {
                    $(".dailymotion-player-root").removeAttr("style").attr("style", "padding-bottom: 0 !important;position: static !important;margin-left: -250px !important;");
                    $(".dailymotion-player-wrapper").removeAttr("style");
                    const handleIntersections = (entries) => {
                        entries.forEach(entry => {
                            if (entry.isIntersecting) {
                                dailymotionPlayer.play();
                            } else {
                                dailymotionPlayer.pause();
                            }
                        });
                    };
                    const observer = new IntersectionObserver(handleIntersections, {threshold: 0.5});
                    observer.observe(document.getElementById($(this).attr('id')));
                } else {
                    console.error("No video or audio element found in the player container.");
                }
            }).catch((e) => console.error(e));
        } catch (error) {
            console.error("An error occurred while creating the player:", error);
        }
    });
}

$(document).ready(function () {
    initDailymotionVideos();
});

function initHighlightButtons() {
    // if we're on the .product_page, then listen for clicks on the button.highlight_button
    // get their data-open and scroll/simulate a click on the corresponding div with the same data-type

    // first, list the already opened highlights, 
    // add an active_highlight class to the corresponding buttons
    // and listen for clicks to the highlights headers to also toggle the active_highlight class on the buttons
    if (document.querySelector('.product_page')) {
        var highlightsWithButtons = document.querySelectorAll('.accordion_item[data-highlight]');
        var openedHighlights = document.querySelectorAll('.accordion_item[data-highlight].opened');
        highlightsWithButtons.forEach(function (highlight) {
            // bind an event listener to the highlight header (.title_container) to toggle the active_highlight class on the corresponding button
            highlight.querySelector('.accordion_title').addEventListener('click', function () {
                // timeout to let the class be added to the parent
                setTimeout(function () {
                    var highlightType = highlight.getAttribute('data-highlight');
                    var highlightButton = document.querySelector('.highlight_button[data-open="' + highlightType + '"]');

                    // collapse all other elements except the target
                    document.querySelectorAll('.accordion_item:not([data-highlight="' + highlightType + '"])').forEach(function (element) {
                        $(element).find('.accordion_contents').slideUp(100);
                        $(element).removeClass('opened');
                    });

                    // remove highlight from all elements except the target
                    document.querySelectorAll('.highlight_button:not([data-open="' + highlightType + '"])').forEach(function (element) {
                        element.classList.remove('active_highlight');
                    });
                    
                    // check if the highlight was opened or closed, and toggle the active_highlight class on the button
                    if (!highlight.classList.contains('opened')) {
                        highlightButton.classList.add('active_highlight');
                    } else {
                        highlightButton.classList.remove('active_highlight');
                    }
                }, 100);
            });
        });

        openedHighlights.forEach(function (highlight) {
            // inside the highlight, get the element with the data-highlight attribute, and get the data-highlight value
            var highlightType = highlight.getAttribute('data-highlight');
            var highlightButton = document.querySelector('.highlight_button[data-open="' + highlightType + '"]');
            if (highlightButton) {
                highlightButton.classList.add('active_highlight');
            }
        });
    }
    
    if (document.querySelector('.product_page')) {
        document.addEventListener('click', function(e) {
            // if the target is a .highlight_button or a child of a .highlight_button, get the data-open attribute
            if (e.target.matches('.highlight_button') || e.target.closest('.highlight_button')) {
                // get the highlight_button to make sure we're not clicking on a child
                var button = e.target.matches('.highlight_button') ? e.target : e.target.closest('.highlight_button');

                var target = button.getAttribute('data-open');
                var targetElement = document.querySelector('[data-highlight="' + target + '"]');
                if (targetElement) {
                    // collapse all elements except the target
                    document.querySelectorAll('.accordion_item:not([data-highlight="' + target + '"])').forEach(function (element) {
                        $(element).find('.accordion_contents').slideUp(100);
                        $(element).removeClass('opened');
                    });

                    // remove outline from all other highlight buttons
                    document.querySelectorAll('.highlight_button:not([data-open="' + target + '"])').forEach(function (element) {
                        element.classList.remove('active_highlight');
                    });

                    // Wait 100ms for the other elements to collapse
                    setTimeout(function () {
                        // scroll to the element with the site_head_wrap height as padding
                        window.scrollTo({
                            // magic number (highlight header size, for ref.) to get a cleaner result, sorry!
                            top: targetElement.offsetTop - 150,
                            behavior: 'smooth'
                        });
                    }, 100);

                    // if not open (parent has 'collapsed' class), simulate a click
                    if (!targetElement.classList.contains('opened')) {
                        $(targetElement).siblings('.accordion_contents').slideDown(300);
                        targetElement.querySelector('.accordion_title').click();
                        // add an active_highlight class to the clicked button
                        button.classList.add('active_highlight');
                    }
                }
            }
        });
    }
}

var qteInProgress = false;

function changeQte(type) {

    if (qteInProgress) {
        return;
    }

    qteInProgress = true;

    var types = ["plus", "less"],
        item,
        loader,
        qtty,
        ajax_url;

    if (types.includes(type)) {
        item = this.getValue("item_id", parseFloat);
        loader = document.getElementById("qte_loader_" + item);
        qtty = this.getValue("selected_qte", parseFloat);

        loader.style.display = "initial";

        switch (type) {
            case "plus":
                qtty += 1;
                break;
            case "less":
                qtty -= 1;
                break;
        }

        // Disable the trigger until the ajax request is done
        $.ajax({
            url: path_relative_root + create_link("order_basket"),
            type: "post",
            data: {
                action: "del",
                panierId: item,
                cad: "non",
                pointcad: 0,
                qteProd: qtty,
                change_qte: true,
            },
            cache: false,
            success: function (res) {
                try {
                    res = JSON.parse(res);
                } catch (e) {
                    console.error("JSON ERROR: could not parse data");
                    return false;
                }

                res = $("<div />").html(res.datas);

                document.getElementById("main_cart_wrapper").innerHTML = $(
                    "#main_cart_wrapper",
                    res
                ).html();
                if (wShop.$refs.wCouponForm) {
                    wShop.$refs.wCouponForm.reloadBloc += 1;
                }

                //update top cart
                var response = ajax_file("ajax_show_basket.php");
                $("#cart_btn").data("cart", response);

                /**
                 * Move out of stock products to the top of the cart
                 */
                updateCartElements();

                if (typeof initApplePayExpressCheckout === "function") {
                    initApplePayExpressCheckout();
                }

                // Enable the trigger after the ajax request is done
                qteInProgress = false;
            },
        });
    }
}

document.addEventListener('DOMContentLoaded', function() {
    initHighlightButtons();
});

function copyToClipboard(textToCopy) {
    let returnEvent;
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        returnEvent = navigator.clipboard.writeText(textToCopy);
    } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        returnEvent = new Promise((res, rej) => {
            // here the magic happens
            document.execCommand('copy') ? res() : rej();
            textArea.remove();
        });
    }

   // create a temporary element to display a message
    const temp = document.createElement("div");
    temp.textContent = Translator.translate('text_copied');
    temp.className = "copied-text-notification";
    document.body.appendChild(temp);
    $('.copied-text-notification').insertAfter('.share_cart');

    setTimeout(function() {
        const copiedTextNotification = document.querySelector(".copied-text-notification");
        copiedTextNotification.classList.add("slide-out");
    }, 3500);

    setTimeout(function() {
        const copiedTextNotification = document.querySelector(".copied-text-notification");
        if (copiedTextNotification) {
            copiedTextNotification.remove();
        }
    }, 4000);
    return returnEvent;
}

function custom_paypal_hipay () {
    $('.paypalButton').on('click', function () {

        $(this).addClass('loading');
    });
}

function initFormItmSelection() {

    const items = document.querySelectorAll('.formes_container .option');

    items.forEach(item => {
        item.addEventListener('click', function() {
            if (this.classList.contains('selected')) {
                this.classList.remove('selected');
            } else {
                this.classList.add('selected');
            }
        });
    });  
}

/**
 * Updates the state of the "Cancel" buttons in the filters menu based on the presence of URL parameters.
 * 
 * This function checks if the current URL contains parameters (indicated by the presence of ".html" followed by additional characters).
 * If parameters are present, it adds the "enabled" class to all "Cancel" buttons within the filters menu.
 * Otherwise, it removes the "enabled" class from these buttons.
 * 
*/


  function updateEnableErase() {
    
    const buttonCancel = document.querySelectorAll('#filters_menu .button.cancel');
    const hasParams = /\.html.+/.test(window.location.href);
  
    buttonCancel.forEach(btn => {
      if (hasParams) {
        btn.classList.add('enabled');
      } else {
        btn.classList.remove('enabled');
      }
    });
  }
  
function moveProductFromWishlistToCart(elemBtn) {

    $(elemBtn).addClass('processing');
    var prodId = $(elemBtn).attr("data-produit-id");
    var colorId = $(elemBtn).attr("data-color-id");
    var sizeId = $(elemBtn).attr("data-size-id");

    let datas = {
        data: $(elemBtn).attr("data-id"),
    };

    if (prodId !== undefined) {
        datas.productId = prodId;
    }

    if (colorId !== undefined) {
        datas.colorId = colorId;
    }

    if (sizeId !== undefined) {
        datas.sizeId = sizeId;
    }

    $.post(path_relative_root + "ajax_add_panier_wishlist.php", datas, function (resp) {
        if (resp === "ok") {
            document.location.href = path_relative_root + create_link('order_basket');
        }
    });
}
