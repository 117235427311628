/* This file contains JS codes that are common to both desktop and mobile to avoid duplicate */

function validationContactMagFormCompulsory(field) {
    if(field.val() === '') {
        field.addClass("inputErr");
        return false;
    }

    field.removeClass('inputErr');
    return true;
}

function validationContactMagFormEmail(field) {
    if(field.val() === '' || validateEmail(field.val()) === false) {
        field.addClass("inputErr");
        return false;
    }

    field.removeClass('inputErr');
    return true;
}

function validationContactMagFormTel(field) {
    if (field.val() === '' || field.intlTelInput("isValidNumber") === false || field.intlTelInput("getNumberType") == 0) {
        field.addClass("inputErr");
        return false;
    }

    field.removeClass('inputErr');
    return true;
}

function validationContactMagFormOption(field, opt1, opt2) {
    if(field.length && !opt1.is(":checked") && !opt2.is(":checked")) {
        field.addClass("inputErr");
        return false;
    }

    field.removeClass("inputErr");
    return true;
}

function checkContactFormOnBlur (el) {
    el.find("#clientNom").blur(function() {
        if (!validationContactMagFormCompulsory (el.find("#clientNom")) ) {
            return false;
        }
    });

    el.find("#clientPrenom").blur(function() {
        if (!validationContactMagFormCompulsory (el.find("#clientPrenom")) ) {
            return false;
        }
    });

    el.find("#clientMail").blur(function() {
        if (!validationContactMagFormEmail (el.find("#clientMail")) ) {
            return false;
        }
    });

    // Remove for WP-27674
    // el.find("#clientTel, #numTelephone").blur(function() {
    //     if (!validationContactMagFormTel (el.find("#clientTel, #numTelephone")) ) {
    //         return false;
    //     }
    // });

    el.find("#clientMessage").blur(function() {
        if (!validationContactMagFormCompulsory (el.find("#clientMessage")) ) {
            return false;
        }
    });

    el.find("#wrapper_optin").change(function() {
        if (!validationContactMagFormOption (el.find("#wrapper_optin"), el.find("#optin_y"), el.find("#optin_n")) ) {
            return false;
        }
    });

    el.find("#wrapper_optin_sms").change(function() {
        if (!validationContactMagFormOption (el.find("#wrapper_optin_sms"), el.find("#optin_sms_y"), el.find("#optin_sms_n")) ) {
            return false;
        }
    });

    return true;
}

function checkContactFormOnClick (el) {
    var form_valid = validationContactMagFormCompulsory (el.find("#clientNom"));

    if(!validationContactMagFormCompulsory (el.find("#clientPrenom"))) {
        form_valid = false;
    }

    if(!validationContactMagFormEmail (el.find("#clientMail"))) {
        form_valid = false;
    }

    // Remove for WP-27674
    // if(!validationContactMagFormTel (el.find("#clientTel, #numTelephone")) && el.find("#clientTel, #numTelephone").val() !== '') {
    //     form_valid = false;
    // }

    if(!validationContactMagFormCompulsory (el.find("#clientMessage"))) {
        form_valid = false;
    }

    if(!validationContactMagFormOption (el.find("#wrapper_optin"), el.find("#optin_y"), el.find("#optin_n"))) {
        form_valid = false;
    }

    if(!validationContactMagFormOption (el.find("#wrapper_optin_sms"), el.find("#optin_sms_y"), el.find("#optin_sms_n"))) {
        form_valid = false;
    }

    return form_valid;
}


// Triggered in wishToBasket function in _app folder on the success of the request
$(window).on("wishToBasket_success", function (resp) {

    var nbWishlist = resp.detail ? resp.detail.nbWishlist : '';
    var nbCart = $('.cart_main_table.basket .cart_product_line').length;

    updateWishCartElems(nbWishlist, nbCart);

});


// Triggered in basketToWish function in _app folder on the success of the request (reloadLeftAndRightTunnel function)
$(window).on("reloadLeftAndRightTunnel_success", function (resp) {

    var nbWishlist = resp.detail ? resp.detail.nbWishlist : '';
    var nbCart = resp.detail ? resp.detail.nbCart : '';

    updateWishCartElems(nbWishlist, nbCart);
    initSwiperTunnel();
});

function updateWishCartElems(nbWishlist, nbCart) {

    // Update visisble elements
    if (nbWishlist != 'undefined') {
        if (nbWishlist == 0) {
            // if = 0, hide wishlist elements
            !$('.cart_main_table.wishlist').hasClass('empty') ? $('.cart_main_table.wishlist').addClass('empty') : '';
            !$('.cart_main_title.wishlist').hasClass('empty') ? $('.cart_main_title.wishlist').addClass('empty') : '';
            !$('.toggleCartTypeBtn.wishlist').hasClass('empty') ? $('.toggleCartTypeBtn.wishlist').addClass('empty') : '';
        } else if (nbWishlist != '') {
            // if > 0, show wishlist elements
            $('.cart_main_table.wishlist').removeClass('empty');
            $('.cart_main_title.wishlist').removeClass('empty');
            $('.toggleCartTypeBtn.wishlist').removeClass('empty');
        }
    }

    // Update visisble elements
    if (nbCart != 'undefined') {
        if (nbCart == 0) {
            // if = 0, hide cart elements
            $('.toggleCartType').addClass('empty_cart');
        } else {
            // if > 0, show cart elements
            $('.toggleCartType').removeClass('empty_cart');
        }
    }
}
